import {useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {  BranchesColumnData, SchedulingColumnData, supportTicketData } from '../../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {  BranchsAction,  ManageOnlineStore } from '../../../../../Data/Tools/ManageContents/ManageContentButtons'
import BranchSubMenu from '../BranchSubMenu/BranchSubMenu'
import ManageOnlineSubMenu from '../../ManageOnlineSubMenu/ManageOnlineSubMenu'

const Schedules = () => {
  const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const handleRowSelected =() =>{}
    return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`Schedulings (Manage Online Store)`} parent={`Manage Store`} />
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container fluid>
        <Row>
        <div className='ms-1 mt-2'>
                    <BranchSubMenu/>

                    </div> 
          <Col sm="12">
            <Card>
               
              <CardBody className='p-2'>
                <> 
                    <div className="table-responsive pt-2">
                    <DataTable className='custom-scrollbar' columns={SchedulingColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    
                </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Schedules