import React, { useState } from "react";
import { Btn, H4, H5, LI, UL } from "../../../AbstractElements";
import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { Field, Formik } from "formik";
import {
    AddNewIdeaStoryAlbum,
    AttributionRequired,
    Keywords,
    StartNewUpload,
    UndoChanges,
} from "../../../utils/Constant";
import CommonSwitchSpan from "../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import CardHeaderCommon from "../../BonusUi/CardHeaderCommon/CardHeaderCommon";
import Dropzone from "react-dropzone-uploader";
import Popup from "../../MasterPopup/Popup";
import {
    manageAlbumFormInitialValue,
    manageAlbumFormValidationSchema,
} from "../../../Data/Tools/ManageContents/FormControlsValidation";
import AddManageMyAlbumsForm from "../../Tools/ManageContents/ManageMyAlbums/AddManageMyAlbumsForm";
import ProductBody from "../../Application/Ecommerce/AddProduct/ProductBody/ProductBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { X } from "react-feather";
import TagsInput from "../../CommonInput/TagsInput";
import CustomSelect from "../../CommonInput/CustomSelect";
import {
    createPostInitialValue,
    createPostSchema,
} from "../../../Data/Post/CreatePostValidationSchema";
import AddDetails from "./AddDetails";
import { setNavId } from "../../../ReduxToolkit/Reducers/AddProductSlice";
import { useAppDispatch } from "../../../ReduxToolkit/Hooks";  
type ProductStockContentProps = {
    form?: string;
};
const ProductStockContent: React.FC<ProductStockContentProps> = ({ form }) => {
    const [openModal, setOpenModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false); 
    const [openPSModal, setOpenPSModal] = useState(false);
    const [albumType, setAlbumType] = useState("1");
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const openModalToggle = () => setOpenModal(!openModal);
    const openModalDetailToggle = () => setIsOpen(!isOpen);
    const handleSubmit = () => {};
    const SocialContentValidation = () => {};
    const getUploadParams = () => {
        return { url: "https://httpbin.org/post" };
    };
    const dispatch = useAppDispatch()

    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    return (
        <div className="px-4 py-4">
            <div>
                <Btn className="ps-1" onClick={openModalToggle}>
                    <i className="bi bi-plus-circle mx-1 text-warning"></i>
                    {AddNewIdeaStoryAlbum}
                </Btn>
            </div>
            <Popup
                title={`Add Product`}
                openModalToggle={() => setOpenPSModal(!openPSModal)}
                isOpen={openPSModal}
                size={`xl`}
                backdrop={true}
                isClose={false}
                CommonFooter={true}
                >
                    
                <div className="p-3"> 
                    <ProductBody />
                </div>
            </Popup>
            <Popup
                title={AddNewIdeaStoryAlbum}
                openModalToggle={openModalToggle}
                isOpen={openModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={true}
                >
                <div className="px-4">
                    <Col>
                        <div className="form-check form-check-inline popup_radio radio-primary  mt-2 px-2 ">
                            <Input
                                id={`select_album`}
                                type="checkbox"
                                name={`radio_upload`}
                                onClick={() => setAlbumType("1")}
                                checked={albumType === "1"}
                            />
                            <Label className="mb-0" for={`select_album`} check>
                                <H5>{`Select an existing album `}</H5>
                            </Label>
                        </div>
                        <div className="form-check form-check-inline  popup_radio radio-primary  mt-2 px-2 ">
                            <Input
                                id={`create_album`}
                                type="checkbox"
                                name={`radio_upload`}
                                onClick={() => setAlbumType("2")}
                                check={albumType !== "2" ? true : false}
                            />
                            <Label className="mb-0" for={`create_album`} check>
                                <H5>{`Create a new album `}</H5>
                            </Label>
                        </div>
                    </Col>
                </div>
                {albumType === "2" && (
                    <div className="px-4 py-2">
                        <Formik
                            initialValues={manageAlbumFormInitialValue}
                            onSubmit={handleSubmit}
                            validationSchema={manageAlbumFormValidationSchema}>
                            {({ errors, values, setFieldValue }) => (
                                <AddManageMyAlbumsForm
                                    submitErrors={submitErrors}
                                    setSubmitError={setSubmitError}
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Formik>
                    </div>
                )}
                {albumType === "1" && (
                    <div className="px-4  py-2">
                        <FormGroup>
                            <Input type="select">
                                <option>{`Select`}</option>
                                <option>
                                    Free for Commercial and Non-profit use
                                </option>
                               
                                <option>Free for Non-Profit use only</option>
                                {/* <option>View Only. No right to download or use. </option>
                            <option>Display only on shopping pages. Not for social media usages</option> */}
                            </Input>
                        </FormGroup>
                    </div>
                )}
            </Popup>
            <Row className="py-2">
                <Col xxl="12">
                    <Formik
                        initialValues={{ email: "" }}
                        onSubmit={handleSubmit}
                        validationSchema={SocialContentValidation}>
                        {({ errors }) => (
                            <Form>
                                <FormGroup floating className={form}>
                                    <Input type="select">
                                        <option>{`Select`}</option>
                                        <option>
                                            Free for Commercial and Non-profit
                                            use
                                        </option>
                                        <option>
                                            Free for Non-Profit use only
                                        </option>
                                         <option>
                                    Premium Content, paid usage 
                                </option>
                                        {/* <option>View Only. No right to download or use. </option>
                                    <option>Display only on shopping pages. Not for social media usages</option> */}
                                    </Input>
                                    <Label>{`License and Right to Use Contents`}</Label>
                                </FormGroup>

                                <div
                                    className={`d-flex align-items-center ${form}`}>
                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                        <CommonSwitchSpan
                                            color={`primary`}
                                            defaultChecked
                                        />
                                    </div>
                                    <Label className="m-l-10" check>
                                        {AttributionRequired}
                                    </Label>
                                </div>

                                <Card className="mb-0">
                                    <CardBody className="pb-0">
                                        <Dropzone
                                            getUploadParams={getUploadParams}
                                            onSubmit={handleSubmit}
                                            maxFiles={1}
                                        />
                                    </CardBody>
                                </Card>

                                {/* <div className='d-flex'>
                                <i className="bi-exclamation-triangle me-1 text-warning"></i>
                                <H6 className=''>{DropZoneWarning}</H6>
                            </div> */}
                                <div className={form}>
                                    <Formik
                                        initialValues={createPostInitialValue}
                                        onSubmit={handleSubmit}
                                        validationSchema={createPostSchema}>
                                        {({
                                            errors,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <Form>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label check>
                                                                {`Category *`}:
                                                            </Label>
                                                            <FormGroup floating>
                                                                <Field
                                                                    className={`custom-select  `}
                                                                    name="CategorySelect"
                                                                    options={
                                                                        cityOptions
                                                                    }
                                                                    component={
                                                                        CustomSelect
                                                                    }
                                                                    placeholder="Category"
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <div className=" col-form-Label">
                                                                <Label check>
                                                                    {Keywords}:
                                                                </Label>
                                                                <TagsInput
                                                                    name="keywords"
                                                                    placeholder="Add keywords"
                                                                    className="w-100"
                                                                    setFieldValue={
                                                                        setFieldValue
                                                                    }
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                <div>
                                    <H4>{`Notes:`}</H4>
                                    <div className="border rounded border-danger p-2">
                                        <p>
                                            <i className="bi-exclamation-triangle me-1 text-warning"></i>
                                            <span>
                                                These attributes will be applied
                                                to all the uploaded images.
                                            </span>
                                        </p>
                                        <UL className="list-content">
                                            <LI>
                                                <FontAwesomeIcon
                                                    icon={fas.faCircle}
                                                    className="pe-2"
                                                    size="xs"
                                                />
                                                {`Pending Content: Admin's approval is required for each of your uploaded contents, before displaying it to users. Check your Media Profile >> "Pending Photos" page.`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon
                                                    icon={fas.faCircle}
                                                    className="pe-2"
                                                    size="xs"
                                                />
                                                {`Please read the terms and conditions to avoid sanctions`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon
                                                    icon={fas.faCircle}
                                                    className="pe-2"
                                                    size="xs"
                                                />
                                                {`Daily maximum number of files to upload: (100)`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon
                                                    icon={fas.faCircle}
                                                    className="pe-2"
                                                    size="xs"
                                                />
                                                {`Daily upload files left: (100)`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon
                                                    icon={fas.faCircle}
                                                    className="pe-2"
                                                    size="xs"
                                                />
                                                {`Not allowed to upload files of violence or pornographic content of any kind`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon
                                                    icon={fas.faCircle}
                                                    className="pe-2"
                                                    size="xs"
                                                />
                                                {`File must be of Authoring`}
                                            </LI>
                                        </UL>
                                    </div>
                                </div>
                
                <div className="d-flex py-3 gap-2">
                                    <Btn color="primary" type="button"  onClick={openModalDetailToggle}>
                                        {"Next Step (Add Details)"}
                                    </Btn> 
                                    {/* <Btn color='secondary' className='mx-2'>{UndoChanges}</Btn> */}
                                    <Popup 
                                    title={'Add Details (step 2)'}
                                    openModalToggle={() => setIsOpen(!isOpen)}
                                    isOpen={isOpen}
                                    size={`xl`}
                                    backdrop={true}
                                    
                                     >
                                    <AddDetails heading={false} popup={true}/>

                                    </Popup>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
};

export default ProductStockContent;
