import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

function Tags() {
  const [title, setTitle] = useState("1 weabb");
  const [description, setDescription] = useState(
    "this website name is 1weabb.com"
  );
  const [keywords, setKeywords] = useState("1 weabb, web, weabb");

  // Every page url changed the useEffect call and update the current page meta tags.
  useEffect(() => {
    // Filter the data for current page

    // set title, description and keywords value for current page.

    console.log("Current page call url", window.location.pathname);
  }, [window.location.pathname]);

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
}

export default Tags;
