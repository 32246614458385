import { CardHeader, Col, Form, Input, Label, Row, Button } from "reactstrap";
import { ChooseYourCurrency, InitialCost, ProductStocks, SellingPrice } from "../../../../../../utils/Constant";
import TypesOfProduct from "./TypesOfProduct";
import { useDispatch } from "react-redux";
import { setFormValue } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";
import BranchSetupPrice from "./BranchSetupPrice";
import { scheduleFormInitialValue, scheduleFormValidationSchema, SchedulesFormValidationProp } from "../../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { Formik } from "formik";
import { useState } from "react";
import CategoryTable from "./CategoryTable";
import { H3 } from "../../../../../../AbstractElements";

const ProductFour = () => {
  const dispatch = useDispatch();
  const [forms, setForms] = useState([scheduleFormInitialValue]);
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleAddBranch = () => {
    setForms([...forms, scheduleFormInitialValue]); // Add a new form
  };

  const handleRemoveBranch = (index: number) => {
    setForms(forms.filter((_, i) => i !== index)); // Remove the form at the specified index
  };

  const handleSubmit = (values: SchedulesFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
    // You can dispatch or handle form values submission here
  };

  return (
    <>
      <CardHeader className="ps-0 pt-2">
        <H3>{'Branch and Price Setup (step 4)'}</H3>
      </CardHeader>
      <div className="sidebar-body">
        {forms.map((formValues, index) => (
          <div key={index} className="mt-3">
            <Formik
              initialValues={formValues}
              onSubmit={handleSubmit}
              validationSchema={scheduleFormValidationSchema}
            >
              {({ errors }) => (
                <Form>
                  <BranchSetupPrice />
                  {/* Add your other form fields here */}
                  
                </Form>
              )}
            </Formik>
            <div className="d-flex gap-2 flex-wrap">
            <Button color="primary" onClick={handleAddBranch}>
          Add Another Branch
        </Button>
        <Button color="danger" onClick={() => handleRemoveBranch(index)}>
                    Remove Branch
                  </Button>
            </div>
          </div>
        ))}
      
      </div>
    </>
  );
};

export default ProductFour;
