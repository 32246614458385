import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
    Categorys,
    DefaultPublicResume,
    Education,
    HobbiesAndInterest,
    Href,
    JobCancelButton,
    JobDesiredHeading,
    JobSubmitButton,
    JobUIDesigner,
    JobUploadYourFiles,
    Languages,
    Objective,
    ResumeName,
    ResumeType,
    SaveThisJob,
    Skill,
    SkillNExperience,
    Training,
    UploadCoverLetterJob,
    UploadRecommendationsJob,
    WorkExperience,
} from "../../../../utils/Constant";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { ErrorMessage, Field, FieldArray } from "formik";
import { Btn, H3, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Link } from "react-router-dom";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import NewAddon from "../../../Tools/ManageOnlineStore/AddonCategories/NewAddon";
import { useState } from "react";

const GeneralInformation = (props: any) => {
    const { errors, values, submitErrors, setSubmitError, setFieldValue } =
        props;
    const [newFormCount, setNewFormCount] = useState(["1"]);

    const addFormHandler = (arrayHelpers: any) => {
        const value = newFormCount.length + 1;
        arrayHelpers.push({
            addOnOption: "",
            addOnExtraPrice: "",
        });
        setNewFormCount((oldArray) => [...oldArray, value.toString()]);
    };
    const removeFormHandler = (
        value: string,
        arrayHelpers: any,
        index: number
    ) => {
        const updatedFormCount = newFormCount.filter((item) => item !== value);
        setNewFormCount(updatedFormCount);
        arrayHelpers.remove(index);
    };
    return (
        <Form className="theme-form">
            <Row>
                <Col xl="4" md="4">
                    <Label check>
                        {ResumeName}:<span className="font-danger">*</span>
                    </Label>
                    <Field
                        type="text"
                        placeholder={ResumeName}
                        name="resumeName"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.resumeName ? "is-invalid" : "is-valid"}`
                        }`}
                        autoFocus={true}
                    />
                    <TextCounter
                        length={values?.resumeName?.length}
                        totalLength="140"
                    />
                    <ErrorMessage
                        name="resumeName"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col xl="4" md="4">
                    <FormGroup>
                        <Label check>
                            {ResumeType}:<span className="font-danger">*</span>
                        </Label>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                {/* <CommonSwitchSpan color={`primary`} /> */}
                                <div className="m-checkbox-inline px-2">
                            <Label for={`edo-ani-a`} check>
                                <Input
                                    className="radio_animated"
                                    id={`edo-ani-a`}
                                    type="radio"
                                    name="rdo-ani"
                                />
                                {`Work`}
                            </Label>
                            <Label for={`edo-ani-a`} check>
                                <Input
                                    className="radio_animated"
                                    id={`edo-ani-a`}
                                    type="radio"
                                    name="rdo-ani"
                                />
                                {`Study`}
                            </Label>
                        </div>
                            </div>
                            {/* <Label className="m-l-10" check>{`Two-Step Authentication`}</Label> */}
                        </div>
                    </FormGroup>
                </Col>
                <Col xl="4" md="4" className=" my-2">
                    <Label>
                        {Categorys}
                        <span className="font-danger">*</span>
                    </Label>
                    <Field
                        as="select"
                        name="subCategory"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.subCategory ? "is-invalid" : "is-valid"}`
                        }`}>
                        <option>
                            {"Select"} {Categorys}
                        </option>
                        <option>{"Germany"}</option>
                        <option>{"Canada"}</option>
                        <option>{"Usa"}</option>
                        <option>{"Aus"}</option>
                    </Field>
                    <ErrorMessage
                        name="subCategory"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col xl="4" md="4">
                    <Label check>
                        {JobDesiredHeading}:
                        <span className="font-danger">*</span>
                    </Label>
                    {/* <Field type="text" placeholder={JobDesiredHeading}  name="jobTitle" className={`form-control ${submitErrors && `${errors.jobTitle ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/> */}
                    <TagsInput
                        name="keywords"
                        placeholder={JobDesiredHeading}
                        setFieldValue={setFieldValue}
                    />
                    <ErrorMessage
                        name="jobTitle"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>

                <Col xl="4" md="4">
                    <Label check>{Objective}:</Label>
                    <Field
                        as="textarea"
                        placeholder={Objective}
                        name="objective"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.objective ? "is-invalid" : "is-valid"}`
                        }`}
                        autoFocus={true}
                    />
                    <ErrorMessage
                        name="objective"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col md="4" className=" ">
                    <FormGroup>
                        <Label className="w-100" check>
                            {`Summery`} :
                        </Label>
                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                        <Field
                            as="textarea"
                            name="description"
                            className="form-control"
                            maxLength={"300"}
                            placeholder="Description/Summary"
                        />
                        <TextCounter
                            length={values?.description?.length}
                            totalLength="300"
                        />
                    </FormGroup>
                </Col>
                <Col md="12" className="mt-3 mb-4">
                    <H3 className="mb-0">{"Work History:"}</H3>
                </Col>
                <FieldArray
                    name="addons"
                    render={(arrayHelpers) => {
                        return (
                            <>
                                {newFormCount.map((item, index) => (
                                    <Container
                                        fluid
                                        className="p-5 mb-4"
                                        id={`prize-container-${item}`}
                                        key={index}
                                        style={{ border: "1px solid #ccc" }}>
                                        <Row>
                                            <Col md={10} className="mb-3">
                                                <h4>Experience {index + 1}</h4>
                                            </Col>
                                            <Col md={2} className="mb-3">
                                                {newFormCount.length !== 1 && (
                                                    <span
                                                        className="btn btn-outline-danger"
                                                        onClick={() =>
                                                            removeFormHandler(
                                                                item,
                                                                arrayHelpers,
                                                                index
                                                            )
                                                        }>
                                                        <i className="icofont icofont-ui-delete"></i>
                                                    </span>
                                                )}
                                                {newFormCount.length ===
                                                    index + 1 && (
                                                    <span
                                                        className="btn btn-outline-dark ms-2"
                                                        onClick={() =>
                                                            addFormHandler(
                                                                arrayHelpers
                                                            )
                                                        }>
                                                        <i className="fa fa-plus"></i>
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Experience {index + 1} Name{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <Field
                                                    name={`workHistory.${index}.workHistoryOption`}
                                                    type="text"
                                                    placeholder="Work History Name"
                                                    maxLength="140"
                                                    
                                                    className={`form-control ${
                                                      submitErrors &&
                                                      `${errors.workHistoryOption?.workHistoryOption ? "is-invalid" : "is-valid"}`
                                                  }`}
                                                />
                                                <TextCounter
                                                    length={
                                                        values?.currentValue
                                                            ?.workHistoryOption
                                                            ?.length
                                                    }
                                                    totalLength="140"
                                                />
                                                <ErrorMessage
                                                    name={`workHistory.${index}.workHistoryOption`}
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Work History and Companies
                                                    Large box
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <Field
                                                    name={`workHistory.${index}.workHistoryQuantity`}
                                                    as="textarea"
                                                    placeholder="Work History Description"
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            values?.workHistoryError
                                                                ?.workHistoryQuantity
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <ErrorMessage
                                                    name={`workHistory.${index}.workHistoryQuantity`}
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                        </Row> */}
                                        <Row>
    <Col sm="6" className="mb-3">
        <Label check>
            Experience {index + 1} Name{" "}
            <span className="txt-danger">*</span>
        </Label>
        <Field
            name={`workHistory.${index}.workHistoryOption`}
            type="text"
            placeholder="Experience Name"
            maxLength="140"
            className={`form-control ${
                errors.workHistory?.[index]?.workHistoryOption && submitErrors
                    ? "is-invalid"
                    : "is-valid"
            }`}
        />
        <TextCounter
            length={values?.workHistory?.[index]?.workHistoryOption?.length || 0}
            totalLength="140"
        />
        <ErrorMessage
            name={`workHistory.${index}.workHistoryOption`}
            component="span"
            className="invalid-feedback"
        />
    </Col>
    <Col sm="6" className="mb-3">
        <Label check>
            Work History and Companies 
            <span className="txt-danger">*</span>
        </Label>
        <Field
            name={`workHistory.${index}.workHistoryQuantity`}
            as="textarea"
            placeholder="Work History Description"
            className={`form-control ${
                errors.workHistory?.[index]?.workHistoryQuantity && submitErrors
                    ? "is-invalid"
                    : "is-valid"
            }`}
        />
        <ErrorMessage
            name={`workHistory.${index}.workHistoryQuantity`}
            component="span"
            className="invalid-feedback"
        />
    </Col>
</Row>
                                    </Container>
                                ))}
                            </>
                        );
                    }}
                />
                <Col md="12" className="mt-3 mb-4">
                    <H3 className="mb-0">{SkillNExperience}</H3>
                </Col>
                <Col xl="6" md="6" className="my-2">
                    <Label check>{Education}:</Label>
                    <Field
                        type="text"
                        placeholder={Education}
                        name="education"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.education ? "is-invalid" : "is-valid"}`
                        }`}
                        autoFocus={true}
                    />
                    <ErrorMessage
                        name="education"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col xl="6" md="6" className="my-2">
                    <Label check>{Training}:</Label>
                    <Field
                        type="text"
                        placeholder={Training}
                        name="traning"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.training ? "is-invalid" : "is-valid"}`
                        }`}
                        autoFocus={true}
                    />
                    <ErrorMessage
                        name="traning"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col xl="6" md="6">
                    <Label>{Languages}</Label>
                    <Field
                        as="select"
                        name="languages"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.languages ? "is-invalid" : "is-valid"}`
                        }`}>
                        <option>
                            {"Select"} {Languages}
                        </option>
                        <option>{"Germany"}</option>
                        <option>{"Canada"}</option>
                        <option>{"Usa"}</option>
                        <option>{"Aus"}</option>
                    </Field>
                    <ErrorMessage
                        name="languages"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col xl="6">
                    <Label check>{"Other Skills"}:</Label>
                    <Field
                        type="text"
                        placeholder={Skill}
                        name="skill"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.skill ? "is-invalid" : "is-valid"}`
                        }`}
                        autoFocus={true}
                    />
                    <ErrorMessage
                        name="skill"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col xl="6" className="my-2">
                    <Label check>{HobbiesAndInterest}:</Label>
                    <Field
                        type="text"
                        placeholder={HobbiesAndInterest}
                        name="hobbiesInterest"
                        className={`form-control ${
                            submitErrors &&
                            `${
                                errors.hobbiesInterest
                                    ? "is-invalid"
                                    : "is-valid"
                            }`
                        }`}
                        autoFocus={true}
                    />
                    <ErrorMessage
                        name="hobbiesInterest"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col> 
                <Row>  
                <Col xl="4" className="my-2">
                    <Label className="col-form-label pt-0" check>
                        {UploadRecommendationsJob}:
                    </Label>
                    <Input type="file" />
                    <ErrorMessage
                        name="hobbiesInterest"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                </Row>
            </Row>

            <Row className="mt-4">
                <Col>
                    <Btn
                        color="primary mx-1"
                        onClick={() => setSubmitError(true)}>
                        {JobSubmitButton}
                    </Btn>
                    <Btn color="light">{JobCancelButton}</Btn>
                </Col>
            </Row>
        </Form>
    );
};

export default GeneralInformation;
