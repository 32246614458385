import { useState } from "react";
import { Col, Row } from "reactstrap";
import { Alerts, P } from "../../AbstractElements";

function ServerErrorMessages({ errorMessages }: any) {
  const [visible, setVisible] = useState<boolean>(true);
  const onDismiss = () => setVisible(false);

  const errorMessageContent = errorMessages
    ? Object.keys(errorMessages).map((item: any) => {
        return (
          <> 
            <P key={item}><i className="fa fa-warning me-2"></i> {errorMessages[item]}</P>
            <br />
          </>
        );
      })
    : "";

  return (
    <>
      {errorMessageContent && (
        <Row>
          <Col md={12}>
            <Alerts fade color="danger" isOpen={visible} toggle={onDismiss}>
              <p>Whoops! There were some problems with your inputs</p>
              {errorMessageContent}
            </Alerts>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ServerErrorMessages;
