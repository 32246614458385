import * as Yup from "yup";

export interface MyResumesValidationProp {
    resumeName: string;
    jobTitle: string;
    objective: string;
    subCategory: string;
    languages: string;
    workHistory: Array<any>;
}

export const workHistoryValues = {
    workHistoryOption: "",
    workHistoryExtraPrice: "",
    workHistoryQuantity: "",
};

export const myResumesFormInitialValue: MyResumesValidationProp = {
    resumeName: "",
    jobTitle: "",
    objective: "",
    subCategory: "",
    languages: "",
    workHistory: [workHistoryValues],
};

export const myReusmesFormSchema = Yup.object().shape({
    resumeName: Yup.string().required("Required"),
    jobTitle: Yup.string().required("Required"),
    objective: Yup.string().required("Required"),
    subCategory: Yup.string().required("Required"),
    languages: Yup.string().required("Required"),
    workExperience: Yup.string().required("Required"),
    education: Yup.string().required("Required"),
    training: Yup.string().required("Required"),
    skill: Yup.string().required("Required"),
    hobbiesInterest: Yup.string().required("Required"),
    workHistory: Yup.array().of(
        Yup.object().shape({
            workHistoryOption: Yup.string().required("Required"),
            workHistoryExtraPrice: Yup.string().required("Required"),
            workHistoryQuantity: Yup.string().required("Required"),
        })
    ),
});
