import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { Image } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";

function AdSense() {
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);

  return (
    <>
      {websiteSettingsData && websiteSettingsData.google_ads_index === "on" && (
        <div className="text-center">
          <Image src={dynamicImage('adsense/ad-image.jpg')}  alt="adsense" width={1200} />
          {websiteSettingsData.google_adsense &&
            websiteSettingsData.google_adsense}
        </div>
      )}
    </>
  );
}

export default AdSense;
