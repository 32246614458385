import { useEffect, useState } from "react";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { Btn, H2, H3, H4 } from "../../../../AbstractElements";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import TestOrQuizSettingsForm from "./TestOrQuizSettingsForm";
import { ErrorMessage, Field, Form } from "formik";
import SpecialParametersMain from "../SpecialParameters/SpecialParametersMain";
import QuizMaterialMain from "../QuizMaterial/QuizMaterialMain";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import GameSettingsForm from "./GameSettingsForm";
import { setActiveTab } from "../../../../ReduxToolkit/Reducers/tabSlice";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { Typeahead } from "react-bootstrap-typeahead";
import GroupMain from "../../../Communication/GroupMain";
import { postTypeData } from "../../../Miscellaneous/Blog/AddPost/AddPost";
import ScheduleType from "../../../CommonInput/ScheduleType";
import Sharing from "./Sharing";
import QuizScene from "../../../QuizzAnimation/NextScreenQuizzAnimation";
import AnimationDropdown from "../../../QuizzAnimation/NextScreenQuizzAnimation";
import AudioDropdownPlayer from "../../../QuizzAnimation/NextAudioDropdownPlayer";
import NextScreenQuizzAnimation from "../../../QuizzAnimation/NextScreenQuizzAnimation";
import {
    CorrectAnsweraudioEffects,
    NextScreenaudioEffects,
    WinneraudioEffects,
    WrongAnsweraudioEffects,
} from "../../../../Data/Quizz/Audio/AudioData";
import {
    CorrectAnsweranimationNames,
    NextScreenanimationNames,
    WinneranimationNames,
    WrongAnsweranimationNames,
} from "../../../../Data/Quizz/Animation/Animation";
import QuizTest from "../../../QuizzAnimation/QuizzTest";
import SubCategory from "../../../CommonInput/SubCategory";
import AnimationMultiSelect from "../../../QuizzAnimation/AnimationMultiSelect";
import AudioMultiSelect from "../../../QuizzAnimation/AudioMultiSelect";

const AddSurveyAndQuizzesForm = (props: any) => {
    const {
        errors,
        submitErrors,
        setSubmitError,
        values,
        setFieldValue,
        addquizsurveysTab,
        testquizsetting,
        setFormType,
        setCategory
    } = props;

    // Quiz Material popup
    const [openModal, setOpenModal] = useState(false);
    const openModalToggle = () => setOpenModal(!openModal);
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state) => state.tabs.activeTab);

    // Special Parameter popup
    const [openSPModal, setOpenSPModal] = useState(false);
    const openSPModalToggle = () => setOpenSPModal(!openSPModal);

    const formTypeHandler = (flag: string) => {
        setFieldValue("formType", flag);
        setFormType(flag)
    };
    console.log(values );
    if(values.subcategory != ''){
        console.log(values.subcategory);
        
        setCategory(values.subcategory)
    }
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault(); // Prevent default form submission

        // Check if there are any validation errors
        const hasErrors = Object.keys(errors).length > 0;

        if (!hasErrors) {
            dispatch(setActiveTab(activeTab + 1));
            // Dispatch form data to the Redux store
            // dispatch(setFormData(values));
            // Optionally, handle any additional logic here
        } else {
            console.log("Form has validation errors:", errors);
        }
    };
    const [openFromModal, setOpenFromModal] = useState(false);

    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const scheduleOptions = [
        { label: "Life Style", value: "Life Style" },
        { label: "Travel", value: "Travel" },
    ];
    return (
        <Form className="form-wizard" onSubmit={handleSubmit}>
            <Col sm="4" className="mb-3">
                <Label check>
                    A Previous Quiz or Survey as a Reference (Optional){" "}
                </Label>
                <Field
                    as="select"
                    className="form-control"
                    name="reference-coupon">
                    <option value={""}>Select a Reference</option>
                </Field>
            </Col>
            <Row>
                <Col sm="4" className="mb-3">
                    <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>
                        General Info
                    </h4>
                </Col>
            </Row>
            <Row>
                <Col sm="12" className="mb-3">
                    <Label check>
                        Form Type
                        <span className="txt-danger">*</span>
                    </Label>
                </Col>
                <Col sm="12">
                <Row>
                    <Col sm="3" className="mb-3 ms-4">
                        <FormGroup check>
                            <Field
                                id="formType3"
                                name="formType"
                                type="radio"
                                value="3"
                                onChange={() => formTypeHandler("3")}
                                className="form-check-input"
                            />
                            <Label
                                className={`mb-0 ${
                                    submitErrors &&
                                    ` ${
                                        errors.formType
                                            ? "text-danger"
                                            : "text-success"
                                    }`
                                }`}
                                for="formType3"
                                check>
                                Educational Game
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3" className="mb-3 ms-4">
                        <FormGroup check>
                            <Field
                                id="formType2"
                                name="formType"
                                type="radio"
                                value="2"
                                onChange={() => formTypeHandler("2")}
                                className="form-check-input"
                            />
                            <Label
                                className={`mb-0 ${
                                    submitErrors &&
                                    ` ${
                                        errors.formType
                                            ? "text-danger"
                                            : "text-success"
                                    }`
                                }`}
                                for="formType2"
                                check>
                                Quiz or Test
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3" className="mb-3 ms-4">
                        <FormGroup check>
                            <Field
                                id="formType1"
                                name="formType"
                                type="radio"
                                value="1"
                                onChange={() => formTypeHandler("1")}
                                className="form-check-input"
                            />
                            <Label
                                className={`mb-0 ${
                                    submitErrors &&
                                    ` ${
                                        errors.formType
                                            ? "text-danger"
                                            : "text-success"
                                    }`
                                }`}
                                for="formType1"
                                check>
                                Survey
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Title <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        type="text"
                        name="title"
                        placeholder="Title"
                        maxLength="140"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.title ? "is-invalid" : "is-valid"}`
                        }`}
                    />
                    <TextCounter
                        length={values?.title?.length}
                        totalLength="140"
                    />
                    <ErrorMessage
                        name="title"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <SubCategory
                        className={`${
                            submitErrors &&
                            `${errors.subcategory ? "is-invalid" : "is-valid"}`
                        }`}
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Description <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        as="textarea"
                        name="description"
                        maxLength="300"
                        placeholder="Description"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.description ? "is-invalid" : "is-valid"}`
                        }`}
                    />
                    <TextCounter
                        length={values?.description?.length}
                        totalLength="300"
                    />
                    <ErrorMessage
                        name="description"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                
                {values?.formType !== "3" && (
                    <Col sm="4" className="mb-3">
                        <Label check>Expiry Date + Hour (Optional)</Label>
                        <Field
                            type="datetime-local"
                            name="dateHoursExpiry"
                            placeholder="Expiry Date + Hour (Optional)"
                            className="form-control"
                        />
                    </Col>
                )}

                <Col sm="4" className="mb-3">
                    <Label check>Keywords (Optional)</Label>
                    <TagsInput
                        name="keywords"
                        placeholder="Enter keywords"
                        className="form-control"
                        setFieldValue={setFieldValue}
                    />
                </Col>

                <Col sm="4" className="mb-3">
                    <Label check>Cover Image </Label>
                    <span className="txt-danger">*</span>
                    <FormGroup>
                        <Field
                            name="image"
                            className={`form-control`}
                            type="file"
                        />
                    </FormGroup>
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Timezone <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        as="select"
                        name="timezone"
                        className={`me-2 mb-2 form-control ${
                            submitErrors &&
                            ` ${errors.timezone ? "is-invalid" : "is-valid"}`
                        }`}>
                        <option value={""}>Select Timezone</option>
                        <option value={"1"}>Timezone</option>
                    </Field>
                    <ErrorMessage
                        name="timezone"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                {values?.formType === "2" && (
                    <Col sm="12" className="mb-3">
                        <Label check>
                            Prerequisites and Requirements (optional)
                        </Label>
                        <Field
                            as="textarea"
                            name="prerequisitesAndRequirements"
                            className="form-control"
                            placeholder="Prerequisites and Requirements"
                            maxLength="300"
                        />
                        <TextCounter
                            length={
                                values?.prerequisitesAndRequirements?.length
                            }
                            totalLength="300"
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <Col sm="4" className="mb-3">
                    <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>
                        Screen Style
                    </h4>
                </Col>
            </Row>
            <Row>
                {values?.formType == "2" && (
                    <Col sm="4" className="mb-3">
                        <Label check>Jump to Other Questions</Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="otherQuestions"
                                    className={`form-control`}
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col>
                )}
                {values?.formType == "2" && (
                    <Col sm="4" className="mb-3">
                        <Label check>Enable Bookmarking Questions </Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="enableBookmark"
                                    className={`form-control`}
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col>
                )}
                {values?.formType === "2" && (
                    <Col sm="4" className="mb-3">
                        <Label check>Pause Timer</Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="pauseTimer"
                                    className={`form-control`}
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col>
                )}
                <>
                    <Col sm="4" className="mb-3">
                        <Label check>Shuffle and Mix Questions</Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="shuffleAndMixQuestions"
                                    className="form-control"
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col>
                    {values?.formType != "3" && (
                        <Col sm="4" className="mb-3">
                            <Label check>
                                Show "Previous" button{" "}
                                <span className="txt-danger">*</span>
                            </Label>
                            <br />
                            <div className="d-flex">
                                <div className="flex-shrink-0  text-end icon-state switch-outline">
                                    <CommonSwitchSpan
                                        color={"primary"}
                                        name="showAnswer"
                                        className={`${
                                            submitErrors &&
                                            `${
                                                errors.showAnswer
                                                    ? "danger"
                                                    : "primary"
                                            }`
                                        }`}
                                        defaultChecked
                                    />
                                </div>
                                <Label className="m-l-10" check></Label>
                            </div>
                        </Col>
                    )}
                   {values?.formType != "3" && <div>
                        <Label check>
                        Displaying Questions{" "}
                            <span className="txt-danger">*</span>
                        </Label>
                        <div className="mb-3 d-flex">
                            <Col sm="4" className="m-checkbox-inline px-2">
                                <Label for={`edo-ani-a`} check>
                                    <Input
                                        className="radio_animated"
                                        id={`edo-ani-a`}
                                        type="radio"
                                        name="rdo-ani"
                                        checked
                                    />
                                    {`1 Question = 1 Screen`}
                                </Label>
                            </Col>
                            <Col sm="4" className="m-checkbox-inline px-2 mt-1">
                                <Label for={`edo-ani-b`} check>
                                    <Input
                                        className="radio_animated"
                                        id={`edo-ani-b`}
                                        type="radio"
                                        name="rdo-ani"
                                    />
                                    {`Multiple Questions = 1 Screen `}
                                </Label>
                            </Col>
                        </div>
                    </div> }
                </>
            </Row>

            {values?.formType == "2" && (
                <TestOrQuizSettingsForm
                    submitErrors={submitErrors}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    addquizsurveysTab={addquizsurveysTab}
                    values={values}
                    testquizsetting={testquizsetting}
                />
            )}
            {values?.formType == "3" && (
                <GameSettingsForm
                    submitErrors={submitErrors}
                    addquizsurveysTab={addquizsurveysTab}
                    errors={errors}
                    setFieldValue={setFieldValue}
                />
            )}
            {values?.formType == "3" && (
                <Row>
                    <Col sm="4" className="my-3">
                        <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>
                            Animation and Audio
                        </h4>
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">Next Screen</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <NextScreenQuizzAnimation
                            animation={NextScreenanimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioDropdownPlayer
                            audioEffects={NextScreenaudioEffects}
                        />
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">While Playing and Answering:</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <AnimationMultiSelect
                            animation={NextScreenanimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioMultiSelect
                            audioEffects={NextScreenaudioEffects}
                        />
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">Gold Winner</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <NextScreenQuizzAnimation
                            animation={CorrectAnsweranimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioDropdownPlayer
                            audioEffects={CorrectAnsweraudioEffects}
                        />
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">Silver Winner</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <NextScreenQuizzAnimation
                            animation={WrongAnsweranimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioDropdownPlayer
                            audioEffects={WrongAnsweraudioEffects}
                        />
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">Bronze Winner</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <NextScreenQuizzAnimation
                            animation={WinneranimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioDropdownPlayer
                            audioEffects={WinneraudioEffects}
                        />
                    </Col>
                </Row>
            )}

            <Sharing />

            {/* <div className="">
                <Col sm="auto" className="mb-3">
                    <Btn
                        color="secondary"
                        type="submit"
                        onClick={() => {
                            setSubmitError(true);
                        }}>
                        Save as a Draft
                    </Btn>
                </Col>
            </div> */}
        </Form>
    );
};

export default AddSurveyAndQuizzesForm;
