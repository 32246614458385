import { Container } from "reactstrap";
import MediaData from "../../../Component/App/MediaData/MediaData";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Home, MediaCap } from "../../../utils/Constant";
import AdSense from "../../../Component/WebsiteSettings/AdSense/Index";
import DefaultFilter from "../../../Component/Application/Ecommerce/Products/ProductFeatures/DefaultFilter";

const Media = () => {
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={MediaCap} parent={Home} />
      <Container fluid>
      <DefaultFilter remove={true}/>      
        <AdSense/>
        <MediaData />
      </Container>
    </div>
  );
};

export default Media;
