import { useEffect } from "react";
import CustomSelect from "./CustomSelect";
import { Field } from "formik";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks"; 
import { useGetCountryDataQuery } from "../../ReduxToolkit/Reducers/Country/countryApiSlice";
import { setCounteries } from "../../ReduxToolkit/Reducers/commonInputSlice";
import Loading from "../Loading/Loading";

interface Country {
  id: string;
  country_name: string;
}

function ScheduleType(props: any) { 
  const { name,Options, className, isMulti, placeholder } = props;  
  
  return (
    <Field
      className={`custom-select ${className}`}
      name={name}
      options={Options}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
}

export default ScheduleType;
