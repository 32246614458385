import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_api } from '../Api';
//test
const baseQuery = fetchBaseQuery({ 
    baseUrl: base_api,
    prepareHeaders : (headers , {getState}) =>{
        const tokenData = JSON.parse(localStorage.getItem("token")!) ? JSON.parse(localStorage.getItem("token")!) : false;
        if(tokenData){ 
            const { token } : any = tokenData; 
            headers.set('Authorization', `Bearer ${token !== undefined && token}`);
        }
        return headers;
    }
});

export const apiSlice = createApi({
    baseQuery,
    tagTypes:["Country", "State","City","Language","Plans","Type","Authentication"],
    endpoints : (builder) =>({

    })
})
