import React, { useState } from "react";  
import { Container } from "reactstrap";
import { Formik } from "formik"; 
 
import { useNavigate } from "react-router-dom"; 
import AddNewEvent from "../../Tools/BrandSupportTools/AttendanceSystem/AddNewEvent";
import ActivityEventForm from "./ActivityEventForm";
import { AddAttendanceNewEventInitialValues, AttendanceNewEventFormValidationSchema } from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";

const ActvityEventModalForm = () => {
  const navigate = useNavigate()
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const handleSubmit = (values: AttendanceFormValidationProp) => {
    console.log("activity values",values);
    // api call and response
    setSubmitError(false);
    // temperary to know the submission of form
    alert("form submitted")
    // navigate(`${process.env.PUBLIC_URL}/activities-meetings`) 
  };
  return (
    <div>  
      <Container className="card p-4" fluid>
        {/* <Message message="Please add at least one branch first to be able to use this form." /> */}

        <Formik
          initialValues={AddAttendanceNewEventInitialValues}
          onSubmit={handleSubmit}
          validationSchema={AttendanceNewEventFormValidationSchema}
        >
          {({ errors,values,setFieldValue }) => (
            <ActivityEventForm
              submitErrors={submitErrors}
              setSubmitError={setSubmitError}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default ActvityEventModalForm;
