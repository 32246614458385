import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileBox from "./ProfileBox";
import { fas } from "@fortawesome/free-solid-svg-icons"; 
import { LI } from "../../../../AbstractElements";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";

const UserProfile = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLLIElement>(null); // Create a ref to track the dropdown

  const { userInfo } = useAppSelector((state) => state.auth);
  const { authUser} = userInfo;

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li
      className={`profile-nav onhover-dropdown ${
        dropdownOpen ? "active_usericon" : ""
      }`}
      ref={dropdownRef} // Attach ref to the LI element
    >
      <div
        className="d-flex profile-media align-items-center"
        onClick={toggleDropdown}
      > 
        <img className="rounded-circle image-radius m-r-15 media" src={authUser.avatar} />
      </div>
      {dropdownOpen && (
        <ProfileBox
          dropdownOpen={dropdownOpen}
          toggleDropdown={toggleDropdown}
        />
      )}
    </li>
  );
};

export default UserProfile;
