// import { Button, Col, TabContent, TabPane } from 'reactstrap';
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { setActiveTab } from "../../../../ReduxToolkit/Reducers/tabSlice";
import SecondLesson from "./SecondLesson";
import FirstSpecialPerameter from "./FirstSpecialPerameter";
import ThirdQuizSurvey from "./ThirdQuizSurvey";
import FourQuestionTab from "./FourQuestionTab";
import { Button, Col, TabContent, TabPane } from "reactstrap";
import { Btn, SVG } from "../../../../AbstractElements";
import CommonButtons from "../../../Buttons/CommonButtons/CommonButtons";
import CommonButton from "../../../Application/Ecommerce/AddProduct/ProductBody/CommonButton/CommonButton";
import { useState } from "react";

const TabAddQuizzRightSide = () => {
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state) => state.tabs.activeTab);
    const totalTabs = 4;

    // Handle "Next" and "Previous" button clicks
    const nextTab = () => {
        if (activeTab < totalTabs) {
            dispatch(setActiveTab(activeTab + 1));
        }
    };

    const previousTab = () => {
        if (activeTab > 1) {
            dispatch(setActiveTab(activeTab - 1));
        }
    };
    const handleSave = () => {
        console.log("All steps saved!");
    };
    const [formType, setFormType] = useState('education');
    const [category,setCategory] = useState('')
    return (
        <Col
            xxl="8"
            xl="8"
            className="box-col-8 flex-fill mt-1 ms-xl-2 position-relative">
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <ThirdQuizSurvey setFormType={setFormType} setCategory={setCategory}/>
                </TabPane>
                <TabPane tabId={2}>
                    <SecondLesson category={category}/>
                </TabPane>
                <TabPane tabId={3}>
                    <FirstSpecialPerameter />
                </TabPane>
                <TabPane tabId={4}>
                    <FourQuestionTab formType={formType}/>
                </TabPane>
            </TabContent>


            <div className="position-relative end-0 bottom-0 quizz-survey-buttons">
                {activeTab > 1 && (
                    <>
                    <Btn
                        onClick={previousTab}
                        className="   ">
                        Previous
                    </Btn> 
                    </>
                )}
                {activeTab < totalTabs ? (
                    <>
                    <Btn onClick={nextTab}   className="ms-2 ">
                        <div className="d-flex align-items-center gap-sm-2 gap-1  ">
                            Next <SVG iconId="front-arrow" />
                        </div>
                    </Btn>  
                     <Btn
                         
                     onClick={previousTab}
                     className="  ms-2 ">
                     Save as a Draft
                 </Btn>
                 </>
                ) : (
                    <Btn
                         
                        className="ms-2   "
                        onClick={handleSave}>
                        Save All Steps
                    </Btn> // Show "Save All Steps" on the last tab
                )}
            </div>
                  {/* <CommonButton step={true} bottombutton={true}/> */}
        </Col>
    );
};

export default TabAddQuizzRightSide;
