import React, { useRef, useEffect } from "react";
import { MenuItem } from "../../Types/Layout/SidebarType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { H3, H4, H5, H6, Image } from "../../AbstractElements";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { setToggleSidebarPopup } from "../../ReduxToolkit/Reducers/LayoutSlice";
import { dynamicImage } from "../../Service";
import { X } from "react-feather";
import { PrivateMenuNames } from "../../Data/LayoutData/SidebarData";

interface MenulistProps {
  menu: MenuItem[];
  heading: string;
  setActiveMenu: React.Dispatch<React.SetStateAction<string[]>>;
  activeMenu: string[];
  level: number;
  className?: string;
}

const Menu: React.FC<MenulistProps> = ({
  menu,
  heading,
  activeMenu,
  level,
  className,
}) => {
  const scrollContainerRef = useRef<HTMLUListElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(true);
  const [openDropdown, setOpenDropdown] = React.useState<string | null>(null);
  const [openSubDropdown, setSubDropdown] = React.useState<string | null>(null);

  const dispatch = useAppDispatch();
  const toggleSidebarPopup = useAppSelector(
    (state) => state.layout.toggleSidebarPopup
  );

  const { userInfo } = useAppSelector((state) => state.auth);

  const location = useLocation(); // To get the current path

  const updateArrows = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust the scroll amount for more gap
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
      // Update arrow visibility after scrolling
      setTimeout(updateArrows, 400); // Delay to match the scrolling duration
    }
  };

  const handleDropdownToggle = (label: string) => {
    setOpenDropdown(openDropdown === label ? null : label);
  };
  const handleSubDropdownToggle = (label: string) => {
    setSubDropdown((prev) => (prev === label ? null : label));
  };
  const handleItemClick = () => {
    dispatch(setToggleSidebarPopup(false)); // Close the sidebar
    setOpenDropdown(null); // Close all dropdowns
  };

  useEffect(() => {
    const handleResize = () => updateArrows();
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        dispatch(setToggleSidebarPopup(false)); // Close the sidebar
        setOpenDropdown(null); // Close all dropdowns
      }
    };

    const handleScroll = (event: Event) => {
      if (openDropdown) {
        event.preventDefault(); // Prevent default scroll behavior
        event.stopPropagation();
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleScroll, { passive: false }); // Add this to handle scroll

    updateArrows(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleScroll); // Clean up
    };
  }, [dispatch, openDropdown]);

  const isToolsActive = () => location.pathname.startsWith("/tools");
  // Function to determine if a menu item or its children are active
  const isDropdownActive = (item: MenuItem): boolean => {
    if (item.title === "Tools") {
      return isToolsActive();
    }
    if (item.children) {
      // Check if any child item is active
      const childActive = item.children.some(
        (subItem) =>
          isActive(subItem.path) ||
          subItem.Items?.some((nestedItem) => isActive(nestedItem.path)) ||
          isDropdownActive(subItem)
      );
      return childActive || isActive(item.path);
    }
    return isActive(item.path);
  };

  const isActive = (path: string) =>
    location.pathname === path || location.pathname.startsWith(path);


  const filterMenu = userInfo ? menu : menu.filter((item) => !PrivateMenuNames.includes(item.title)); 
  
  return (
    <div>
      <div
        className={`bg_light ${openDropdown ? "dropdown-open" : ""}`}
        ref={sidebarRef}
      >
        <div className={`container-fluid bg_light ps-0 ps-md-2 menu_wraper`}>
          <div className="d-flex align-items-center l-h-45">
            <div className="flex-shrink-0">
              {/* {showLeftArrow && (
                <button
                  className="btn-left btn-link p-2 text-dark"
                  onClick={() => scroll('left')}
                >
                  <i className="fa fa-arrow-left"></i>
                </button>
              )} */}
            </div>

            <div
              className={` ${
                toggleSidebarPopup ? "nav nav_block open" : ""
              } p-3 p-md-0 nav flex-grow-1`}
            >
              <div className="d-flex justify-content-between mb-3 d-lg-none">
                <Link to={`${process.env.PUBLIC_URL}/dashboard/user-dashboard`}>
                  <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/logo-1.png")}
                    alt="logo"
                  />
                  <Image
                    className="img-fluid for-dark"
                    src={dynamicImage("logo/logo.png")}
                    alt="logo"
                  />
                </Link>
                <i
                  onClick={() => dispatch(setToggleSidebarPopup(false))}
                  className="search-close-icon"
                >
                  <X />
                </i>
              </div>
              <div className="text-start d-md-none d-block mb-3">
                <Link className="bg-success px-3 py-2 d-inline-block">
                  Download App
                </Link>
              </div>
              <ul
                className={`${
                  toggleSidebarPopup ? "" : "nav"
                } overflow_auto nav-fill  small flex-nowrap`}
                ref={scrollContainerRef}
              >
                {filterMenu &&
                  filterMenu.map((item : any, index) => {
                    const filterChildMenu = userInfo ? item.children : item.children && item.children.filter((item : any) => !PrivateMenuNames.includes(item.title));
                    return (
                      <React.Fragment key={index}>
                        <li
                          className={`nav-item ${
                            isDropdownActive(item) ? "active" : ""
                          } ${openDropdown === item.title ? "open" : ""}`}
                        >
                          <Link
                            to={item.path}
                            className={`nav-link ${
                              isDropdownActive(item) ? "active" : ""
                            }`}
                            onClick={
                              item.children
                                ? (e) => {
                                    e.preventDefault();
                                    handleDropdownToggle(item.title);
                                  }
                                : handleItemClick
                            }
                          >
                            {item.icon && <FontAwesomeIcon icon={item.icon} />}
                            <span className="mx-2">{item.title}</span>
                            {item.children && (
                              <i
                                className={`fa fa-chevron-down ${
                                  toggleSidebarPopup ? "" : ""
                                }`}
                              ></i>
                            )}
                          </Link>
                          {item.children && (
                            <ul
                              className={`cust-dropdown-menu bg-white text-start ${
                                openDropdown === item.title
                                  ? item.title === "Tools"
                                    ? "tools_col d-block"
                                    : "open"
                                  : ""
                              }`}
                            >
                              {item.Heading && (
                                <div className="text-center pb-1 py-md-2 border-bottom mb-2">
                                  <H4 className="heading-drop d-none d-md-block">
                                    {item.Heading}
                                  </H4>
                                </div>
                              )}
                              <div
                                className={`${
                                  item.title === "Tools"
                                    ? "gap-5 tools_col d-block d-md-flex"
                                    : ""
                                }`}
                              >
                                { 
                                filterChildMenu && filterChildMenu.map((subItem: any, subIndex: number) => { 
                                  return (
                                    <React.Fragment key={subIndex}>
                                      <li
                                        className={`${
                                          item.title === "Tools"
                                            ? openSubDropdown === subItem.title
                                              ? "tools_part sub_dropdown"
                                              : "tools_part"
                                            : ""
                                        } ${
                                          isDropdownActive(subItem) ? "active" : ""
                                        }`}
                                      >
                                        {item.title === "Tools" ? (
                                          <H5
                                            className="mb-2  "
                                            onClick={() =>
                                              handleSubDropdownToggle(subItem.title)
                                            }
                                          >
                                            {subItem.title}{" "}
                                            <i
                                              className={`${
                                                openSubDropdown === subItem.title
                                                  ? "uparrow"
                                                  : ""
                                              } fa fa-chevron-down  `}
                                            ></i>
                                          </H5>
                                        ) : (
                                          <Link
                                            to={subItem.path}
                                            className={
                                              isDropdownActive(subItem)
                                                ? "active"
                                                : ""
                                            }
                                            onClick={handleItemClick}
                                          >
                                            {subItem.icon && (
                                              <FontAwesomeIcon
                                                icon={subItem.icon}
                                                className="item-icon"
                                              />
                                            )}
                                            {subItem.iconbi && (
                                              <i
                                                className={`${subItem.iconbi} pe-0 me-0`}
                                              />
                                            )}
                                            <span className="ms-md-3 ms-2">
                                              {subItem.title}
                                            </span>
                                          </Link>
                                        )}
                                        {subItem.Items && (
                                          <ul className="submenu">
                                            {subItem.Items.map((label, ind) => (
                                              <li key={ind}>
                                                <Link
                                                  to={label.path}
                                                  className={`py-0 d-inline-block d-flex align-items-start ${
                                                    isDropdownActive(label)
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={handleItemClick}
                                                >
                                                  {label.icon && (
                                                    <FontAwesomeIcon
                                                      icon={label.icon}
                                                      className="item-icon"
                                                    />
                                                  )}
                                                  <div className="ms-2">
                                                    <H6 className="fw-normal">
                                                      {label.subtitle}
                                                    </H6>
                                                    <p className="tools_description text-muted mb-0">
                                                      {label.description}
                                                    </p>
                                                  </div>
                                                </Link>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </li>
                                    </React.Fragment>
                                  )
                                })}
                              </div>
                            </ul>
                          )}
                        </li>
                      </React.Fragment>
                    )
                  })}
              </ul>
            </div>
            <div className="flex-shrink-0">
              {/* {showRightArrow && (
                <button
                  className="btn-right btn-link p-2 text-dark"
                  onClick={() => scroll('right')}
                >
                  <i className="fa fa-arrow-right"></i>
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
