import { useState } from "react";
import { Row, Col, Label, FormGroup, Button } from "reactstrap";
import { Btn, H3, H4, H5 } from "../../../../AbstractElements";
import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import SubCategory from "../../../CommonInput/SubCategory";
import CommonModal from "../../../../Component/Ui-Kits/Modal/Common/CommonModal";
import {
    ErrorMessage,
    Field,
    Form,
    FieldArray,
    useFormikContext,
    Formik,
} from "formik";
import StudyMaterialLessonMain from "../StudyMaterialLesson/StudyMaterialLessonMain";
import QuizMaterialMain from "../QuizMaterial/QuizMaterialMain";
import TextCounter from "../../../CommonInput/TextCounter";
import AlternativeQuestions from "../../../CommonInput/AlternativeQuestions";
import AnswerInput from "../../../CommonInput/AnswerInput";
import CorrectAnswerField from "./CorrectAnswerField";
import { X } from "react-feather";
import AddAnswersMain from "./AddAnswersMain";
import TestOrQuizSettingsForm from "../SurveyAndQuizzes/TestOrQuizSettingsForm";
import { scheduleFormInitialValue, scheduleFormValidationSchema } from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import LessonField from "../../../CommonInput/LessonField";
import { QuestionFormValidationProp } from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";

const AddQuestionsForm = (props: any) => {
    const [currectAnswerCount, setCurrectAnswerCount] = useState(["1"]);
    const [currecQuestionCount, setCurrectcurrecQuestionCount] = useState([
        "0",
    ]);

    const addCurrectAnswerFieldCountHandler = (arrayHelpers: any) => {
        const value = currectAnswerCount.length + 1;
        setCurrectAnswerCount((oldArray) => [...oldArray, value.toString()]);
        arrayHelpers.push("");
    };
    const addCurrecQuestionCountHandler = (arrayHelpers: any) => {
        const value = currecQuestionCount.length + 1;
        console.log(value);

        setCurrectcurrecQuestionCount((oldArray) => [
            ...oldArray,
            value.toString(),
        ]);
        arrayHelpers.push("");
    };

    const removeCurrectAnswerFieldHandler = (
        value: string,
        arrayHelpers: any,
        index: number
    ) => {
        const updatedFormCount = currectAnswerCount.filter(
            (item) => item !== value
        );
        setCurrectAnswerCount(updatedFormCount);
        arrayHelpers.remove(index);
    };
    const removeCurrecQuestionCountHandler = (
        value: string,
        arrayHelpers: any,
        index: number
    ) => {
        const updatedFormCount = currecQuestionCount.filter(
            (item) => item !== value
        );
        setCurrectcurrecQuestionCount(updatedFormCount);
        arrayHelpers.remove(index);
    };

    // Quiz Material popup.
    const [openModal, setOpenModal] = useState(false);
    const openModalToggle = () => setOpenModal(!openModal);

    // Study Material & Lesson popup.
    const [openSMLModal, setOpenSMLModal] = useState(false);
    const openSMLModalToggle = () => setOpenSMLModal(!openSMLModal);

    const {
        errors,
        submitErrors,
        setSubmitError,
        formtype,
        values,
        testquizsetting,
    } = props;
    console.log(formtype);
    const handleSubmit = (values: QuestionFormValidationProp) => {
        console.log(values);
        setSubmitError(false);
      };
    const [showOptions, setShowOptions] = useState(false);
    const [showinputanswer, setshowinputanswer] = useState(true);

    // Toggle function for the answer type options
    const toggleAnswerType = () => {
        console.log(showOptions);

        setShowOptions((prevShowOptions) => !prevShowOptions); // Toggles between showing and hiding options
    };
    const { setFieldValue } = useFormikContext();
    const [selectedNegativeMarking, setSelectedNegativeMarking] = useState(
        values.negativeMakingOptional === "zero" ? true : false
    );
    const handleNegativeMarkingChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setSelectedNegativeMarking(!selectedNegativeMarking);
        // setSelectedNegativeMarking(value); // Update the state with the selected value
        setFieldValue("negativeMakingOptional", value); // Update Formik field value
        setFieldValue("pointGrade", value === "negative-grade" ? -5 : 0); // Set point grade based on selection
    };
    const [
        selectedNegativeMarkingObligator,
        setSelectedNegativeMarkingObligator,
    ] = useState("zero1");
    return (
        <Form className="form-wizard">
            <Row>
                {props?.addQuizMaterial && (
                    <Col sm="4" className="mb-3">
                        <Label check>Quiz Material (Optional)</Label>
                        <FormGroup>
                            <Field
                                as="select"
                                name="lesson"
                                className="form-control mb-2">
                                <option value={""}>
                                    Select a Quiz Material{" "}
                                </option>
                            </Field>
                            <span
                                className="btn btn-primary mb-2"
                                onClick={openModalToggle}>
                                <i className="fa fa-plus-circle"></i> New Quiz
                                Material
                            </span>
                        </FormGroup>
                        <CommonModal
                            isOpen={openModal}
                            toggle={openModalToggle}
                            heading="Login"
                            size="xl"
                            sizeTitle="Add a Quiz Material">
                            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                                {" "}
                                <QuizMaterialMain addQuestions={false} />
                            </div>
                        </CommonModal>
                    </Col>
                )}
            </Row>
            <FieldArray
                name="answers"
                render={(arrayHelpers) => {
                    return (
                        <>
                            {currecQuestionCount.map(
                                (questionItem, questionIndex) => (
                                    <>
                                        <Row>
                                            <H4 className="my-2 text-muted">{`Add Question: ${
                                                questionIndex + 1
                                            }`}</H4>
                                            <Row>
                                                <H4 className="my-2 text-muted">{`Group Related Questions (optional)`}</H4>
                                                <Col sm="12" className="mb-3">
                                                    <Label check>
                                                        (Use group names if you
                                                        want to divide a quiz to
                                                        multiple related
                                                        sections, example:
                                                        Crammer, Reading,
                                                        Writing).
                                                    </Label>
                                                    <Row className="ms-2">
                        <Col sm="4">
                            <FormGroup check>
                                <Field
                                    id="noneOption"
                                    name="GroupanswerInput"
                                    type="radio"
                                    value="none"
                                    className="form-check-input"
                                    checked={values.GroupanswerInput === 'none'}
                                    onChange={() => {
                                        setFieldValue('GroupanswerInput', 'none');
                                    }}
                                />
                                <Label className="mb-0" htmlFor="noneOption" check>
                                    None
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="4">
                            <FormGroup check>
                                <Field
                                    id="existingQuestionGroup"
                                    name="GroupanswerInput"
                                    type="radio"
                                    value="existingGroup"
                                    className="form-check-input"
                                    checked={values.GroupanswerInput === 'existingGroup'}
                                    onChange={() => {
                                        setFieldValue('GroupanswerInput', 'existingGroup');
                                    }}
                                />
                                <Label className="mb-0" htmlFor="existingQuestionGroup" check>
                                    Select an Existing Question Group
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="4">
                            <FormGroup check>
                                <Field
                                    id="newQuestionGroup"
                                    name="GroupanswerInput"
                                    type="radio"
                                    value="newGroup"
                                    className="form-check-input"
                                    checked={values.GroupanswerInput === 'newGroup'}
                                    onChange={() => {
                                        setFieldValue('GroupanswerInput', 'newGroup');
                                    }}
                                />
                                <Label className="mb-0" htmlFor="newQuestionGroup" check>
                                    Create a New Question Group
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                                                </Col>
                                                {
                                                    values.GroupanswerInput === "existingGroup" &&<>
                                                    <Formik
                                        initialValues={scheduleFormInitialValue}
                                        onSubmit={handleSubmit}
                                        validationSchema={
                                            scheduleFormValidationSchema
                                        }>
                                        {({ errors }) => (
                                            <Form className="form-wizard">
                                                <Row className="align-items-center">
                                                    <Col
                                                        sm="12"
                                                        className="mb-3">
                                                        <LessonField
                                                            name="branch"
                                                            isMulti={true}
                                                            placeholder="Select"
                                                        />
                                                    </Col>
                                                    
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                                    </>
                                                }
                                               { values.GroupanswerInput === "newGroup" && <>
                                                <Col sm="4" className="mb-3">
                                                    <Label check>
                                                        Question Group Name{" "}
                                                        <span className="txt-danger">
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        name="title"
                                                        placeholder="Enter a Question Group Name"
                                                        maxLength="140"
                                                        className={`form-control ${
                                                            submitErrors &&
                                                            `${
                                                                errors.title
                                                                    ? "is-invalid"
                                                                    : "is-valid"
                                                            }`
                                                        }`}
                                                    />
                                                    <TextCounter
                                                        length={
                                                            values?.title
                                                                ?.length
                                                        }
                                                        totalLength="140"
                                                    />
                                                    <ErrorMessage
                                                        name="title"
                                                        component="span"
                                                        className="invalid-feedback"
                                                    />
                                                </Col>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="w-100"
                                                            check>
                                                            Question Group
                                                            Description
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                        <Field
                                                            as="textarea"
                                                            name="description"
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.description
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                            maxLength={"300"}
                                                            placeholder="Enter a Question Group Description "
                                                        />
                                                        <TextCounter
                                                            length={
                                                                values
                                                                    ?.description
                                                                    ?.length
                                                            }
                                                            totalLength="300"
                                                        />
                                                        <ErrorMessage
                                                            name="description"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </>}
                                            </Row>
                                            <Row>
                                                <H4 className="my-2 text-muted">
                                                    Quiz Material (optional)
                                                </H4>
                                                <Col sm="12" className=" ">
                                                    <Label check>
                                                        (Is the participant
                                                        required to provide
                                                        answers based on a
                                                        specific quiz material
                                                        (a text, video or
                                                        audio)?).
                                                    </Label>
                                                    <Row className="ms-2 mb-2">
                                                        <Col sm="4">
                                                            <FormGroup check>
                                                                <Field
                                                                    id="textInputquizz"
                                                                    name="quizmaterialanswerInput"
                                                                    type="radio"
                                                                    value="none"
                                                                    className="form-check-input"
                                                                    checked={
                                                                        values.quizmaterialanswerInput ===
                                                                        "none"
                                                                    }
                                                                    onChange={() => {
                                                                        setFieldValue(
                                                                            "quizmaterialanswerInput",
                                                                            "none"
                                                                        );
                                                                        setshowinputanswer(
                                                                            true
                                                                        );
                                                                        setShowOptions(
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    htmlFor="textInputquizz"
                                                                    check>
                                                                    None
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="4">
                                                            <FormGroup check>
                                                                <Field
                                                                    id="textInputExistingQuiz"
                                                                    name="quizmaterialanswerInput"
                                                                    type="radio"
                                                                    value="text"
                                                                    className="form-check-input"
                                                                    checked={
                                                                        values.quizmaterialanswerInput ===
                                                                        "Existing Quiz Material"
                                                                    }
                                                                    onChange={() => {
                                                                        setFieldValue(
                                                                            "quizmaterialanswerInput",
                                                                            "Existing Quiz Material"
                                                                        );
                                                                        setshowinputanswer(
                                                                            true
                                                                        );
                                                                        setShowOptions(
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    htmlFor="textInputExistingQuiz"
                                                                    check>
                                                                    Select am
                                                                    Existing
                                                                    Quiz
                                                                    Material
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="4">
                                                            <FormGroup check>
                                                                <Field
                                                                    id="textInputNewQuizMaterial"
                                                                    name="quizmaterialanswerInput"
                                                                    type="radio"
                                                                    value="text"
                                                                    className="form-check-input"
                                                                    checked={
                                                                        values.quizmaterialanswerInput ===
                                                                        "Create a New Quiz Material"
                                                                    }
                                                                    onChange={() => {
                                                                        setFieldValue(
                                                                            "quizmaterialanswerInput",
                                                                            "Create a New Quiz Material"
                                                                        );
                                                                        setshowinputanswer(
                                                                            true
                                                                        );
                                                                        setShowOptions(
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    htmlFor="textInputNewQuizMaterial"
                                                                    check>
                                                                    Create a New
                                                                    Quiz
                                                                    Material
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                    {
                                                    values.quizmaterialanswerInput === "Existing Quiz Material" &&<>
                                                    <Formik
                                        initialValues={scheduleFormInitialValue}
                                        onSubmit={handleSubmit}
                                        validationSchema={
                                            scheduleFormValidationSchema
                                        }>
                                        {({ errors }) => (
                                            <Form className="form-wizard">
                                                <Row className="align-items-center">
                                                    <Col
                                                        sm="12"
                                                        className="mb-3">
                                                        <LessonField
                                                            name="branch"
                                                            isMulti={true}
                                                            placeholder="Select"
                                                        />
                                                    </Col>
                                                    
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                                    </>
                                                }
                                                { values.quizmaterialanswerInput === "Create a New Quiz Material" && <>
                                                <Col sm="4" className=" ">
                                                    <Label check>
                                                    Quiz Material Name {" "}
                                                        <span className="txt-danger">
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        name="quizmaterialtitle"
                                                        placeholder="Enter a Quiz Material Name "
                                                        maxLength="140"
                                                        className={`form-control ${
                                                            submitErrors &&
                                                            `${
                                                                errors.quizmaterialtitle
                                                                    ? "is-invalid"
                                                                    : "is-valid"
                                                            }`
                                                        }`}
                                                    />
                                                    <TextCounter
                                                        length={
                                                            values?.quizmaterialtitle
                                                                ?.length
                                                        }
                                                        totalLength="140"
                                                    />
                                                    <ErrorMessage
                                                        name="quizmaterialtitle"
                                                        component="span"
                                                        className="invalid-feedback"
                                                    />
                                                </Col>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="w-100"
                                                            check>
                                                            Quiz Material Text
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                        <Field
                                                            as="textarea"
                                                            name="quizmaterialdescription"
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.quizmaterialdescription
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                            maxLength={"300"}
                                                            placeholder="Enter a Quiz Material Text"
                                                        />
                                                        <TextCounter
                                                            length={
                                                                values
                                                                    ?.quizmaterialdescription
                                                                    ?.length
                                                            }
                                                            totalLength="300"
                                                        />
                                                        <ErrorMessage
                                                            name="quizmaterialdescription"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm='4' >
                                                <FormGroup>
                                <Label htmlFor="audioFile" className="">Audio Material</Label>
                                <input
                                    id="audioFile"
                                    name="audioFile"
                                    type="file"
                                    accept="audio/*"
                                    className="form-control"
                                    onChange={(event) => {
                                        if (event.currentTarget.files) {
                                            setFieldValue("audioFile", event.currentTarget.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                                                </Col>
                                                <Col sm="4">
                            <FormGroup>
                                <Label htmlFor="videoURL">Video URL</Label>
                                <Field
                                    id="videoURL"
                                    name="videoURL"
                                    type="url"
                                    placeholder="Enter video URL"
                                    className="form-control"
                                />
                            </FormGroup>
                        </Col>
                                                </>}

                                                    </Row>
                                                </Col>
                                            </Row>
                                            <H4 className="my-2 text-muted">
                                                Question Settings
                                            </H4>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Question Text{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <Field
                                                    as="textarea"
                                                    name="questionText"
                                                    placeholder="Question Text"
                                                    maxLength="300"
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            errors.questionText
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <TextCounter
                                                    length={
                                                        values?.questionText
                                                            ?.length
                                                    }
                                                    totalLength={"300"}
                                                />
                                                <ErrorMessage
                                                    name="questionText"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Question Image (Optional)
                                                </Label>
                                                <FormGroup>
                                                    <Field
                                                        className="form-control"
                                                        name="questionImage"
                                                        type="file"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Question Audio (Optional)
                                                </Label>
                                                <FormGroup>
                                                    <Field
                                                        className="form-control"
                                                        name="questionAudio"
                                                        type="file"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Duration in Minutes{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <Field
                                                    type="text"
                                                    name="durationInMinutes"
                                                    placeholder="Duration in Minutes"
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            errors.durationInMinutes
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <ErrorMessage
                                                    name="durationInMinutes"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    {formtype == "1"
                                                        ? "Point / Grade (optional)"
                                                        : "Point / Grade"}

                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <Field
                                                    type="text"
                                                    name="pointGrade"
                                                    placeholder="Point / Grade"
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            errors.pointGrade
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <ErrorMessage
                                                    name="pointGrade"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Obligatory Question{" "}
                                                    <span className="txt-danger">
                                                        *
                                                    </span>
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="obligationQuestion"
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.obligationQuestion
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                    <Col sm="4" className="mb-3">
         
         <Label check>
         Negative Marking for Wrong Answer (Optional)
         </Label>
         <Row>
           <Col sm="auto" className="mb-3 ms-4">
             <FormGroup check>
               <Field
                 id="zero"
                 name="negativeMakingOptional"
                 type="radio"
                 value="zero"
                 className="form-check-input"
                 checked={selectedNegativeMarking == false? true :false} // Check if "zero" is selected
                 onChange={handleNegativeMarkingChange} // Handle radio button change
               />
               <Label className="mb-0" for="zero" check>Zero ( 0 )</Label>
             </FormGroup>
           </Col>

           <Col sm="auto" className="mb-3 ms-4">
             <FormGroup check>
               <Field
                 id="negative-grade"
                 name="negativeMakingOptional"
                 type="radio"
                 value="negative-grade"
                 className="form-check-input"
                 checked={selectedNegativeMarking == true? true :false} // Check if "negative-grade" is selected
                 onChange={handleNegativeMarkingChange} // Handle radio button change
               />
               <Label className="mb-0" for="negative-grade" check>Negative Grade</Label>
             </FormGroup>
           </Col>
         </Row>
       </Col> 
                    {values.negativeMakingOptional === "negative-grade" && (
                        <Col sm="4" className="mb-3">
                            <FormGroup check>
                                <Label check>
                                    Negative Marking Value{" "}
                                    <span className="txt-danger">*</span>
                                </Label>
                                <Field
                                    className="form-control"
                                    type="number"
                                    name="pointGrade"
                                    placeholder="-0.5"
                                    step="0.5" // Allow changes in increments of 0.5
                                    min="-100" // Set a minimum value to allow negative numbers
                                    value={
                                        values.pointGrade < 0
                                            ? values.pointGrade
                                            : -Math.abs(values.pointGrade)
                                    } // Ensure it's negative
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const value = parseFloat(
                                            e.target.value
                                        );
                                        // Ensure the value is a valid number and adjust to negative if necessary
                                        if (!isNaN(value)) {
                                            setFieldValue(
                                                "pointGrade",
                                                value < 0
                                                    ? value
                                                    : -Math.abs(value)
                                            );
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    name="pointGrade"
                                    component="span"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                        </Col>
                    )}
                    </Row>
                                       
                                        <Row>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Calculator
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showCalculator"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Translator
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showTranslator"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Dictionary
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showDictionary"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Search the Web
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="searchTheWeb"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>

                                            <Col sm="11" className="mb-3">
                                                <Label check>
                                                    Answering Type
                                                </Label>
                                                <Row>
                                                    {/* Answering Type Options */}
                                                    <Col
                                                        sm="4"
                                                        className="mb-3 ms-4">
                                                        <FormGroup check>
                                                            <Field
                                                                id="select4Options"
                                                                name="answerInput"
                                                                type="radio"
                                                                value="multiple-choice"
                                                                className="form-check-input"
                                                                checked={
                                                                    values.answerInput ===
                                                                    "multiple-choice"
                                                                }
                                                                onChange={() => {
                                                                    setFieldValue(
                                                                        "answerInput",
                                                                        "multiple-choice"
                                                                    );
                                                                    setShowOptions(
                                                                        true
                                                                    );
                                                                    setshowinputanswer(
                                                                        false
                                                                    );
                                                                }}
                                                            />
                                                            <Label
                                                                className={`mb-0 ${
                                                                    submitErrors &&
                                                                    `${
                                                                        errors.formType
                                                                            ? "text-danger"
                                                                            : "text-success"
                                                                    }`
                                                                }`}
                                                                htmlFor="select4Options"
                                                                check>
                                                                Select 1 option
                                                                from 4 Options
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col
                                                        sm="4"
                                                        className="mb-3 ms-4">
                                                        <FormGroup check>
                                                            <Field
                                                                id="textInput"
                                                                name="answerInput"
                                                                type="radio"
                                                                value="text"
                                                                className="form-check-input"
                                                                checked={
                                                                    values.answerInput ===
                                                                    "text"
                                                                }
                                                                onChange={() => {
                                                                    setFieldValue(
                                                                        "answerInput",
                                                                        "text"
                                                                    );
                                                                    setshowinputanswer(
                                                                        true
                                                                    );
                                                                    setShowOptions(
                                                                        false
                                                                    );
                                                                }}
                                                            />
                                                            <Label
                                                                className="mb-0"
                                                                htmlFor="textInput"
                                                                check>
                                                                Enter an Answer
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    {showinputanswer && (
                                                        <Col
                                                            sm="4"
                                                            className="mb-3">
                                                            <Label check>
                                                                Answer Input{" "}
                                                                <span className="txt-danger">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            <AnswerInput
                                                                name="answerInput"
                                                                className={`form-control ${
                                                                    submitErrors &&
                                                                    `${
                                                                        errors.answerInput
                                                                            ? "is-invalid"
                                                                            : "is-valid"
                                                                    }`
                                                                }`}
                                                            />
                                                            <ErrorMessage
                                                                name="answerInput"
                                                                component="span"
                                                                className="invalid-feedback"
                                                            />
                                                        </Col>
                                                    )}
                                                    <Col
                                                        sm="4"
                                                        className="ms-4">
                                                        {showinputanswer && (
                                                            <>
                                                                <CorrectAnswerField
                                                                    submitErrors={
                                                                        submitErrors
                                                                    }
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    values={
                                                                        values
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* Conditionally render options if 'Select 1 option from 4 Options' is selected */}
                                                {showOptions && (
                                                    <AddAnswersMain
                                                        showOptions={
                                                            showOptions
                                                        }
                                                    />
                                                )}
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-between">
                                            <Col sm="auto" className="mb-3">
                                                {currecQuestionCount.length >
                                                    1 && (
                                                    <span
                                                        onClick={() =>
                                                            removeCurrecQuestionCountHandler(
                                                                questionItem,
                                                                arrayHelpers,
                                                                questionIndex
                                                            )
                                                        }
                                                        className="badge b-ln-height badge-danger">
                                                        <i className="fa fa-minus"></i>
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                )
                            )}

                            <Row className="">
                                <Col sm="4" className="mb-3">
                                    <Label check>
                                        Alternative Questions (Optional)
                                    </Label>
                                    <AlternativeQuestions
                                        name="aleternativeQuestions"
                                        isMulti={true}
                                        placeholder="Please select alternative questions"
                                    />
                                </Col>
                                <Col sm="4" className="mb-3">
                                    <Btn
                                        color="secondary"
                                        type="button"
                                        onClick={() =>
                                            addCurrecQuestionCountHandler(
                                                arrayHelpers
                                            )
                                        }>
                                        Add another Question
                                    </Btn>
                                </Col>
                            </Row>
                        </>
                    );
                }}
            />
        </Form>
    );
};

export default AddQuestionsForm;
