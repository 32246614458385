import { useState } from "react"; 
import { Formik } from "formik";
import {
  QuestionFormValidationProp,
  questionFormValidationSchema,
  questionFormInitialValue,
  surveyAndQuizzesFormInitialValue,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import AddQuestionsForm from "../Questions/AddQuestionsForm";
import { H4, H5 } from "../../../../AbstractElements";
import { Col, Form, Row } from "reactstrap";
import { scheduleFormInitialValue, scheduleFormValidationSchema } from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import LessonField from "../../../CommonInput/LessonField";
const FourQuestionTab = (props: any) => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: QuestionFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
  };
  const [activeCheckbox, setActiveCheckbox] = useState<"first" | "second">(
    "first"
);

const handleCheckboxChange = (checkbox: "first" | "second") => {
    // Toggle between checkboxes
    setActiveCheckbox(checkbox);
};
  return(
    <>
       <H4 className="mb-2">Add Questions (required)</H4>

    
        <Row  className="my-2 px-3">
                <Col>
                    <div>
                        <div>
                            <div
                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                    activeCheckbox === "first"
                                        ? "transform-scale"
                                        : ""
                                }`}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={activeCheckbox === "first"}
                                        onChange={() =>
                                            handleCheckboxChange("first")
                                        }
                                    />
                                    <H5 className="ms-2">
                                        Select an Exsiting Question
                                    </H5>
                                </label>
                            </div>
                            <div
                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                    activeCheckbox === "second"
                                        ? "transform-scale"
                                        : ""
                                }`}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={activeCheckbox === "second"}
                                        onChange={() =>
                                            handleCheckboxChange("second")
                                        }
                                    />
                                    <H5 className="ms-2">
                                        Create a New Question
                                    </H5>
                                </label>
                            </div>
                        </div>

                        {/* Display content based on which checkbox is active */}
                        <div>
                            {activeCheckbox === "first" ? (
                                <div className="px-4 py-2"> 
                                    <Formik
                                        initialValues={scheduleFormInitialValue}
                                        onSubmit={handleSubmit}
                                        validationSchema={
                                            scheduleFormValidationSchema
                                        }>
                                        {({ errors }) => (
                                            <Form className="form-wizard">
                                                <Row className="align-items-center">
                                                    <Col
                                                        sm="12"
                                                        className="mb-3">
                                                        <LessonField
                                                            name="branch"
                                                            isMulti={true}
                                                            placeholder="Select"
                                                        />
                                                    </Col>
                                                    
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            ) : (
                                <>
                                  <Formik
          initialValues={questionFormInitialValue } 
          onSubmit={handleSubmit}
          validationSchema={questionFormValidationSchema}
        >
          {({ errors, values }) => (
            <AddQuestionsForm
              submitErrors={submitErrors}
              setSubmitError={setSubmitError}
              errors={errors}
              addQuestions={true}
              values={values}
              addQuizMaterial={props.addQuizMaterial}
              testquizsetting={true}
              formtype={props.formType}
               
            />
          )}
        </Formik> 
                                    
                                </>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
    </>
  );
};

export default FourQuestionTab;
