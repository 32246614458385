 
import { Card, Row } from "reactstrap";
import { dynamicImage } from "../../../Service";
import { Link } from "react-router-dom";
import { H4, LI, P, UL } from "../../../AbstractElements";
import { useState, useCallback } from "react";
import { Carousel } from "react-responsive-carousel";
import MediaModal from "./MediaModal";

const images_a = [
  "masonry/1.jpg",
  "masonry/1.jpg",
  "masonry/1.jpg",
  "masonry/1.jpg",
  "masonry/1.jpg",
];
const images_b = [
  "masonry/2.jpg",
  "masonry/2.jpg",
  "masonry/2.jpg",
  "masonry/2.jpg",
  "masonry/2.jpg",
];

export const items = [
  { id: 1, title: "Media 1" },
  { id: 2, title: "Media 2" },
  { id: 3, title: "Media 3" },
  { id: 4, title: "Media 4" },
];

const MediaData = (props: any) => {
  
  
  const [dataId, setDataId] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handlePopupClick = useCallback((id: number) => {
    setDataId(id);
    setOpenModal(true);
  }, []);

  const renderCarousel = (index: number) => (
    <Carousel
      className="owl-carousel owl-theme"
      showStatus={false}
      showIndicators={false}
      showArrows={true}
      swipeable={false}
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      stopOnHover={true}
      emulateTouch={false}
      onClickItem={() => handlePopupClick(index)}
    >
      {(index % 2 === 0 ? images_a : images_b).map((image, idx) => (
        <div className="item" key={idx}>
          <img src={dynamicImage(image)} alt="" />
          <div className="product-hover">
            <UL className="simple-list flex-row">
              <LI>
                <Link to={``}>
                  <i className="icon-eye"></i>                                                                                                     
                </Link>
              </LI>
            </UL>
          </div>
        </div>
      ))}
    </Carousel>
  );

  return (
    <div className="product-wrapper-grid">
      <Row>
        {items.map((item, index) => (
          <div id="gridId" className="col-xl-3 col-sm-6" key={item.id}>
            <Card>
              <div className="product-box border">
                <div className="product-img">
                  {renderCarousel(index)}
                </div>
                <div className="product-details">
                  <Link to={`${process.env.PUBLIC_URL}/ecommerce/productpage`}>
                    <H4>{item.title}</H4>
                  </Link>
                  <P>Description of {item.title}</P>
                </div>
              </div>
            </Card>
          </div>
        ))}
        {openModal && (
          <MediaModal
            value={openModal}
            setOpenModal={setOpenModal}
            dataId={dataId}
            items={items}    
          />
        )}
      </Row>
    </div>
  );
};

export default MediaData;
