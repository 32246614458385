// FireworksDisplay.js
import React, { useEffect, useState } from "react";
import { Fireworks } from "@fireworks-js/react";

const FireworksDisplay = ({   fireworksRef,showFireworks }) => {
 
  return (
    <div>
      {showFireworks && (
        <Fireworks
          ref={fireworksRef}
          options={{
            rocketsPoint: { min: 50, max: 50 },
            opacity: 0.5,
            
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </div>
  );
};

export default FireworksDisplay;
