import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { ErrorMessage, Field } from "formik";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import GroupMain from "../../../Communication/GroupMain";
import TagsInput from "../../../CommonInput/TagsInput";
import ScheduleType from "../../../CommonInput/ScheduleType";
import { useState } from "react";

function Sharing(props: any) {
  const { submitErrors, errors, setFieldValue ,addquizsurveysTab,nomediacenter,displaymediacenter} = props;

  const formTypeHandler = (name: string, flag: string) => {
    setFieldValue(name, flag);
  };
  const scheduleOptions = [
    { label: "Life Style", value: "Life Style" },
    { label: "Travel", value: "Travel" },
];
const [openFromModal, setOpenFromModal] = useState(false);

    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
  return (
    <>
       <Row>
                <Col className="mb-3">
                    <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>
                        Sharing
                    </h4>
                </Col>
            </Row>
            <Row>
              {displaymediacenter ? '': <Col sm="auto">
                    <FormGroup>
                        <div> 
                        <Label check>{"Display on My Media Center"}:</Label>
                        </div>
                        <div className="d-flex flex-wrap gap-2 align-items-center">
                        <div className="m-checkbox-inline px-2">
                            <Label for={`edo-ani-a`} check>
                                <Input
                                    className="radio_animated"
                                    id={`edo-ani-a`}
                                    type="radio"
                                    name="rdo-ani"
                                />
                                {`To Anyone`}
                            </Label>
                        </div>
                        <div className="m-checkbox-inline px-2 mt-1">
                            <Label for={`edo-ani-b`} check>
                                <Input
                                    className="radio_animated"
                                    id={`edo-ani-b`}
                                    type="radio"
                                    name="rdo-ani"
                                />
                                {`To Followers Only`}
                            </Label>
                        </div>
                       {nomediacenter ? '' :  <div className="m-checkbox-inline px-2 mt-1">
                            <Label for={`edo-ani-c`} check>
                                <Input
                                    className="radio_animated"
                                    id={`edo-ani-c`}
                                    type="radio"
                                    name="rdo-ani"
                                />
                                {`Do Not Display on My Media Center `}
                            </Label>
                        </div>}
                        </div>
                    </FormGroup>
                </Col>}
                <Row>
                <Col sm="12">
                    <FormGroup>
                        <FormGroup>
                            <Label check>
                                {"Select Groups"}{" "}:
                            </Label>
                            <ScheduleType
                                name="employscheduleType"
                                placeholder="Select Groups"
                                Options={scheduleOptions}
                                isMulti={true}
                            />
                            <ErrorMessage
                                name="employscheduleType"
                                component="span"
                                className="invalid-feedback"
                            />
                        </FormGroup> 
                        <span>Create a New Group</span>
                        <span
                            className="mt-1 btn btn-primary px-3 mx-2"
                            onClick={openFromModalToggle}>
                            <i className="icofont icofont-users"></i>
                        </span>
                        <CommonModal
                            isOpen={openFromModal}
                            toggle={openFromModalToggle}
                            sizeTitle="Add a Group"
                            size="lg">
                            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up"> 
                                <GroupMain />
                            </div>
                        </CommonModal>
                    </FormGroup>
                </Col>
                </Row>
                <Row>

                <Col sm="12">
                    <FormGroup>
                        <div className=" col-form-Label">
                            <FormGroup className=" ">
                                <Label check className="mb-1">Usernames :</Label>
                                <TagsInput
                                    name="Usernames"
                                    username={true}
                                    placeholder="Enter Usernames"
                                    className={`form-control pt-0 ${
                                        submitErrors &&
                                        `${
                                            errors.subject
                                                ? "is-invalid"
                                                : "is-valid"
                                        }`
                                    }`}
                                    setFieldValue={setFieldValue}
                                /> 
                            </FormGroup>
                        </div>
                    </FormGroup>
                </Col>
                </Row>
            </Row>
    </>
  );
}

export default Sharing;
