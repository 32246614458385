import { useState } from "react";
import { Field, Formik } from "formik"; 
import {
  SpecialParametersFormValidationProp,
  specialParametersFormInitialValue,
  specialParametersFormValidationSchema,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import AddSpecialParametersForm from "../SpecialParameters/AddSpecialParametersForm";
import { H4, H5 } from "../../../../AbstractElements";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AddManageMyAlbumsForm from "../../ManageContents/ManageMyAlbums/AddManageMyAlbumsForm";
import { manageAlbumFormInitialValue, manageAlbumFormValidationSchema } from "../../../../Data/Tools/ManageContents/FormControlsValidation";
import CommonButton from "../../../Application/Ecommerce/AddProduct/ProductBody/CommonButton/CommonButton";

const FirstSpecialPerameter = () => {
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const [albumType, setAlbumType] = useState("1");

    const handleSubmit = (values: SpecialParametersFormValidationProp) => {
        console.log(values);
        setSubmitError(false);
    };
    const [activeCheckbox, setActiveCheckbox] = useState<'none' |'first' | 'second'>('none');

    const handleCheckboxChange = (checkbox:'none' | 'first' | 'second') => {
      // Toggle between checkboxes
      setActiveCheckbox(checkbox);
    };
    return (
        <>
            <H4 className="mb-2">Add Special Parameters (optional)</H4> 
           
    <div className="mx-2 mb-3">
        <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${activeCheckbox === "none" ? "transform-scale" : ""}`}>
        <label>
          <input
            type="checkbox"
            checked={activeCheckbox === 'none'}
            onChange={() => handleCheckboxChange('none')}
          />
                                    <H5 className="ms-2">{`None`}</H5>

        </label>
        </div>
        <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${activeCheckbox === "first" ? "transform-scale" : ""}`}>
        <label>
          <input
            type="checkbox"
            checked={activeCheckbox === 'first'}
            onChange={() => handleCheckboxChange('first')}
          />
                                    <H5 className="ms-2">{`Select existing special parameters`}</H5>

        </label>
        </div>
        <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${activeCheckbox === "second" ? "transform-scale" : ""}`}>
        <label>
          <input
            type="checkbox"
            checked={activeCheckbox === 'second'}
            onChange={() => handleCheckboxChange('second')}
          />
                                          <H5 className="ms-2">{`Create new special parameters`}</H5>

        </label>
        </div>
      </div>
            {activeCheckbox === 'first'?  (
                <div className="px-4 py-2">
                    <FormGroup>
                        <Input type="select">
                            <option>{`Select`}</option>
                            <option>
                                Free for Commercial and Non-profit use
                            </option>
                            <option>Free for Non-Profit use only</option>
                        </Input>
                    </FormGroup>
                </div>
            ): activeCheckbox === 'second'? (
                <Formik
                    initialValues={specialParametersFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={specialParametersFormValidationSchema}
                >
                    {({ errors, values, setFieldValue }) => (
                        <AddSpecialParametersForm
                            submitErrors={submitErrors}
                            setSubmitError={setSubmitError}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Formik>
            ):""}
                     {/* {activeCheckbox != 'none' ? <CommonButton step={true} bottombutton={true}/> : ''} */}


        </>
    );
};

export default FirstSpecialPerameter;
