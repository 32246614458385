import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { AddProductServiceList, BrandTools, ManageStore, ProductForm } from "../../../../utils/Constant";
import { H3 } from "../../../../AbstractElements"; 
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs"; 
import ProductServiceSubMenu from "../../../../Pages/Tools/ManageOnlineStore/ManageBranches/ManageProductServices/ProductServiceSubMenu";
import TabBrandSupport from "./TabAddQuizz";
import ManageOnlineSubMenu from "../../../../Pages/Tools/ManageOnlineStore/ManageOnlineSubMenu/ManageOnlineSubMenu";
import SubMenu from "../../../Application/SubMenu/SubMenu";
import StudyMaterialSubMenu from "../../../Application/StudyMaterialSubMenu/StudyMaterialSubMenu";
import TabQuizz from "./TabAddQuizz";

const AddQuizzMain = () => {
  return (
    <>
      <Breadcrumbs mainTitle={`${AddProductServiceList} (${BrandTools})`} parent={ManageStore} />
      <SubMenu/>
      <Container fluid> 
        <Row>
          <Col xs="12">
            <Card> 
              <div className='my-2 ms-1'> 
              <StudyMaterialSubMenu /> 
                  </div>  
              <CardBody>
                <TabQuizz />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddQuizzMain;
