import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { ErrorMessage, Field, Formik } from "formik";
import { Btn, H3, H4, H5, LI, UL } from "../../../AbstractElements";
import {
    Description,
    InviteUsers,
    Keywords,
    PostContent,
    PostTitlePlaceholder,
    WhoCanSee,
} from "../../../utils/Constant";
import CommonModal from "../../Ui-Kits/Modal/Common/CommonModal";
import { useState } from "react";
import InviteContactMain from "../../Communication/InviteContactMain";
import GroupMain from "../../Communication/GroupMain";
import { Typeahead } from "react-bootstrap-typeahead";
import { postTypeData } from "../../Miscellaneous/Blog/AddPost/AddPost";
import Country from "../../CommonInput/Country";
import TextCounter from "../../CommonInput/TextCounter";
import TagsInput from "../../CommonInput/TagsInput";
import {
    createPostInitialValue,
    createPostSchema,
} from "../../../Data/Post/CreatePostValidationSchema";
import { SimpleMdeReact } from "react-simplemde-editor";
// import RadioTypeForm from "../../Miscellaneous/Blog/AddPost/RadioTypeForm"
import * as Yup from "yup";
import CreatePostForm from "./CreatePostForm";
import CustomSelect from "../../CommonInput/CustomSelect";
import City from "../../CommonInput/City";
import ScheduleType from "../../CommonInput/ScheduleType";
import Sharing from "../../Tools/BrandSupportTools/SurveyAndQuizzes/Sharing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

export const postschema = Yup.object().shape({});
const CreatePostContainer = () => {
    const [postType, setPostType] = useState<number | null>(1);
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleSubmit = () => {};
    const [openToModal, setOpenToModal] = useState(false);
    const openToModalToggle = () => {
        setOpenToModal(!openToModal);
    };

    const [openFromModal, setOpenFromModal] = useState(false);
    const [images, setImages] = useState([] as any);
    const [imageMaxLength, setImageMaxLength] = useState(false);
    const imageURLS = useState([]);

    const onImageChange = (e: any) => {
        let totalfiles = e.target.files.length;
        if (totalfiles < 1) return;
        else if (totalfiles > 10) {
            setImages([]);
            setImageMaxLength(true);
            e.target.value = "";
            return;
        } else if (totalfiles > 0 && totalfiles <= 10) {
            setImages([...e.target.files]);
        }

        images.forEach((image: any) => {
            if (imageURLS.length < 10) {
                imageURLS.push(URL.createObjectURL(image));
            }
        });
    };
    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    const scheduleOptions = [
        { label: "Full Time", value: "full-time" },
        { label: "Part Time", value: "part-time" },
        { label: "Freelancing", value: "freelancing" },
    ];
    return (
        <Container fluid>
            <Row className="px-2">
                <Col sm="12">
                    <Card>
                        <CardBody className="add-post">
                            <Formik
                                initialValues={createPostInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={createPostSchema}>
                                {({ errors, values, setFieldValue }) => (
                                    <>
                                     <Row className="pb-3">
      <Col  md='7'>
                                <H4>{`Notes:`}</H4>
                                <div className='border rounded border-danger p-2 mt-2'>
                                   
                                    <UL className='list-content '>
                                        <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Left Monthly Balance of Free Submission: 1 `}</LI>
                                        <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Price (30 Days) for additional submission: 3 USD (for 3 additional submissions)  `}</LI>
                                         </UL>
                                </div>
                                </Col>
                            </Row>
                                        <Form>
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label check>
                                                            {`Post Type`}{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        <div className="px-2">
                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_upload"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            1
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        1
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_upload">
                                                                    <H5>
                                                                        Article
                                                                        or Blog
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadc"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            2
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        2
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadc">
                                                                    <H5>
                                                                        Customer’s
                                                                        Wish of
                                                                        a
                                                                        Product
                                                                        or a
                                                                        Service
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadj"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            3
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        3
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadj">
                                                                    <H5>
                                                                        Job
                                                                        Opening:
                                                                        you want
                                                                        to hire
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadn"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            4
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        4
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadn">
                                                                    <H5>
                                                                        Notification
                                                                        Screen
                                                                        on My
                                                                        Media
                                                                        Center
                                                                    </H5>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label check>
                                                            {`Title`}{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        <Field
                                                            type="text"
                                                            name="Title"
                                                            placeholder={`Title`}
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.subject
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                        />
                                                        <TextCounter
                                                            length={
                                                                values?.subject
                                                                    ?.length
                                                            }
                                                            totalLength="140"
                                                        />
                                                        <ErrorMessage
                                                            name="Title"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                    <Label check>Image</Label>
                                                    <FormGroup>
                                                        <Field
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.image
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                            name="image"
                                                            type="file"
                                                            multiple
                                                            accept="image/*"
                                                            onChange={
                                                                onImageChange
                                                            }
                                                        />
                                                        {images.length > 10 ? (
                                                            <span className="text-danger">
                                                                Max limit 10
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <ErrorMessage
                                                            name="image"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                        <ul>
                                                            Total Image :{" "}
                                                            {images.length}
                                                        </ul>
                                                    </FormGroup>

                                                    {postType === 1 && (
                                                        <>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="w-100"
                                                                            check>
                                                                            {
                                                                                Description
                                                                            }{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                            :
                                                                        </Label>
                                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.description
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                            maxLength={
                                                                                "300"
                                                                            }
                                                                            placeholder="Description/Summary"
                                                                        />
                                                                        <TextCounter
                                                                            length={
                                                                                values
                                                                                    ?.description
                                                                                    ?.length
                                                                            }
                                                                            totalLength="300"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="span"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <Col
                                                                sm="12"
                                                                md="12">
                                                                <FormGroup>
                                                                    <Label
                                                                        className="w-100"
                                                                        check>
                                                                        {
                                                                            PostContent
                                                                        }
                                                                        :
                                                                    </Label>
                                                                    <SimpleMdeReact
                                                                        id="editor_container"
                                                                        options={{
                                                                            spellChecker:
                                                                                false,
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </>
                                                    )}
                                                    {postType === 2 && (
                                                        <>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormGroup>
                                                                    <Label
                                                                        check>
                                                                        Product
                                                                        or
                                                                        Service
                                                                        Name{" "}
                                                                        <span className="txt-danger">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    <Field
                                                                        name={` excatNumber`}
                                                                        as="select"
                                                                        className={`form-control  `}>
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }>
                                                                            select
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                "equal_among_winners"
                                                                            }>
                                                                            value
                                                                            1
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                "separate_prizes_for_each_winner"
                                                                            }>
                                                                            value
                                                                            2
                                                                        </option>
                                                                    </Field>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="w-100"
                                                                            check>
                                                                            {
                                                                                Description
                                                                            }{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                            :
                                                                        </Label>
                                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.description
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                            maxLength={
                                                                                "300"
                                                                            }
                                                                            placeholder="Description/Summary"
                                                                        />
                                                                        <TextCounter
                                                                            length={
                                                                                values
                                                                                    ?.description
                                                                                    ?.length
                                                                            }
                                                                            totalLength="300"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="span"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <FormGroup>
                                                                <Label check>
                                                                    External
                                                                    Links{" "}
                                                                    <span className="txt-danger">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                <Field
                                                                    name="externallink"
                                                                    type="text"
                                                                    placeholder="Enter your external link"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.externallink
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="externallink"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Vender’s Country`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="country"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Desired Budget`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="number"
                                                                    name="desired_budget"
                                                                    placeholder="Enter desired budget"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.desired_budget
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="desired_budget"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Max Budget`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="number"
                                                                    name="max_budget"
                                                                    placeholder="Enter max budget"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.max_budget
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="max_budget"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        </>
                                                    )}
                                                    {postType === 3 && (
                                                        <>
                                                            <Col
                                                                sm="4"
                                                                className="mb-3">
                                                                <Label check>
                                                                    A Previous
                                                                    Job Opening
                                                                    as a
                                                                    Reference
                                                                    (Optional){" "}
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-control"
                                                                    name="reference-coupon">
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }>
                                                                        Select a
                                                                        Reference
                                                                    </option>
                                                                </Field>
                                                            </Col>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <div className=" col-form-Label">
                                                                    {
                                                                        "Required Skills (keywords)"
                                                                    }
                                                                    :
                                                                    <TagsInput
                                                                        name="skillskeywords"
                                                                        placeholder="Add keywords"
                                                                        className="w-100"
                                                                        setFieldValue={
                                                                            setFieldValue
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Minimum Years of experience`}{" "}
                                                                    <span className=" ">
                                                                        *
                                                                    </span>
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="text"
                                                                    name="employexperience"
                                                                    placeholder={`Minimum Years of experience`}
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.employexperience
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="employexperience"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Hiring Duration`}{" "}
                                                                    <span className=" ">
                                                                        *
                                                                    </span>
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    name="employjobLocationType"
                                                                    className="form-control">
                                                                    <option value="">{`1 Day`}</option>
                                                                    <option value="remote">{`Up to 1 Week `}</option>
                                                                    <option value="specific-address">{`1 Week to 1 Month`}</option>
                                                                    <option value="specific-address">{`1 Month to 3 Months `}</option>
                                                                    <option value="specific-address">{`3 Months to 6 Months `}</option>
                                                                    <option value="specific-address">{`6 Months to 1 Year`}</option>
                                                                    <option value="specific-address">{`1 Year to 2 Years`}</option>
                                                                    <option value="specific-address">{`Long Term`}</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            {/* Schedule Type */}

                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Schedule Type`}
                                                                    :
                                                                </Label>
                                                                <ScheduleType
                                                                    name="employjobLocationType"
                                                                    placeholder="Select Schedule Type"
                                                                    Options={
                                                                        scheduleOptions
                                                                    }
                                                                    className={`${
                                                                        errors &&
                                                                        `${
                                                                            errors.employjobLocationType
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            {/* salary */}
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Salary`}:
                                                                </Label>
                                                                <Field
                                                                    type="text"
                                                                    name="employsalary"
                                                                    placeholder={`Salary`}
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.employperiodMonths
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="employsalary"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                            {/* Job Location Type */}
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Job Location Type`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    name="employjobLocationType"
                                                                    className="form-control">
                                                                    <option value="">{`Select Job Location Type`}</option>
                                                                    <option value="remote">{`Remote`}</option>
                                                                    <option value="specific-address">{`Specific Address`}</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Job Location`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="jobcountry"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="jobcountry"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="w-100"
                                                                            check>
                                                                            {
                                                                                Description
                                                                            }{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                            :
                                                                        </Label>
                                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.description
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                            maxLength={
                                                                                "300"
                                                                            }
                                                                            placeholder="Description/Summary"
                                                                        />
                                                                        <TextCounter
                                                                            length={
                                                                                values
                                                                                    ?.description
                                                                                    ?.length
                                                                            }
                                                                            totalLength="300"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="span"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    {postType === 4 && (
                                                        <>
                                                            <Col
                                                                sm="4"
                                                                className="mb-3">
                                                                <Label check>
                                                                    {" "}
                                                                    A Previous
                                                                    Notification
                                                                    Screen as a
                                                                    Reference
                                                                    (Optional){" "}
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-control"
                                                                    name="reference-coupon">
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }>
                                                                        Select a
                                                                        Reference
                                                                    </option>
                                                                </Field>
                                                            </Col>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Target Countries`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="targetcountry"
                                                                    placeholder="Select"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="w-100"
                                                                            check>
                                                                            {
                                                                                Description
                                                                            }{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                            :
                                                                        </Label>
                                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.description
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                            maxLength={
                                                                                "300"
                                                                            }
                                                                            placeholder="Description/Summary"
                                                                        />
                                                                        <TextCounter
                                                                            length={
                                                                                values
                                                                                    ?.description
                                                                                    ?.length
                                                                            }
                                                                            totalLength="300"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="span"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Country`}:
                                                                </Label>
                                                                <Country
                                                                    name="country"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        </>
                                                    )}

                                                    {postType === 3 ? (
                                                        ""
                                                    ) : (
                                                        <FormGroup>
                                                            <div className=" col-form-Label">
                                                                {Keywords}:
                                                                <TagsInput
                                                                    name="keywords"
                                                                    placeholder="Add keywords"
                                                                    className="w-100"
                                                                    setFieldValue={
                                                                        setFieldValue
                                                                    }
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    )}
                                                    <Sharing />
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div className="btn-showcase">
                                            <Btn
                                                color="primary"
                                                type="submit"
                                                onClick={() =>
                                                    setSubmitError(true)
                                                }>{`Save`}</Btn>
                                            {/* <Btn color="light" type="reset">
                                        {`Cancel`}
                                        </Btn> */}
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CreatePostContainer;
