import { Button, Modal, ModalBody, ModalHeader, Progress } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import FireworksDisplay from "./Fireworks";

const ResultsPopup = ({ isOpen, toggle, correctAnswersCount, totalQuestions }) => {
    const [stars, setStars] = useState("");
    const [title, setTitle] = useState("");
    const [showFireworks, setShowFireworks] = useState(false);
    const fireworksRef = useRef(null);

    useEffect(() => {
        const percentage = (correctAnswersCount / totalQuestions) * 100;

        if (percentage === 100) {
            setStars("⭐️⭐️⭐️⭐️⭐️"); // Gold Winner
            setTitle("Gold Winner! 🥇");
            setShowFireworks(true);
        } else if (percentage >= 70) {
            setStars("⭐️⭐️⭐️⭐️"); // Silver Winner
            setTitle("Silver Winner! 🥈");
            setShowFireworks(false);
        } else if (percentage >= 40) {
            setStars("⭐️⭐️"); // Bronze Winner
            setTitle("Bronze Winner! 🥉");
            setShowFireworks(false);
        } else {
            setStars("");
            setTitle("Better luck next time!");
            setShowFireworks(false);
        }
    }, [correctAnswersCount, totalQuestions]); // Add dependencies to useEffect 
    const scorePercentage = (correctAnswersCount / totalQuestions) * 100;
    const passingScore = Math.ceil(totalQuestions * 0.6);
    return (
        <>
            <Modal
                isOpen={isOpen}
                size="lg"
                toggle={() => {
                    console.log(`showFireworks value on close: ${showFireworks}`); // Log the value before closing
                    onClose();
                    setShowFireworks(false);
                }}
                className="modal-dialog-centered"
            >
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className="text-center">
                    <h2>{stars}</h2> 
                    <h4 className="mb-4">Your Score: {correctAnswersCount} / {totalQuestions}</h4>
                    <Progress value={scorePercentage} />
                    <h5 className="mt-4">{correctAnswersCount >= passingScore ? "You Passed!" : "You Failed!"}</h5>
                    {correctAnswersCount < passingScore && (
                        <p className="mt-3">Better luck next time!</p>
                    )}
                </ModalBody>
            </Modal>

            {showFireworks && isOpen &&<FireworksDisplay showFireworks={showFireworks}   />}
        </>
    );
};

export default ResultsPopup;
