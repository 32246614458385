import { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    Modal,
    Row,
} from "reactstrap";
import { Btn, H4, SVG } from "../../../AbstractElements";
import { Carousel } from "react-responsive-carousel";  
import ActvityEventModalForm from "./ActivityEventModalForm"; 
import { Field, Formik } from "formik";

export interface MediaModalInterface {
    value?: boolean; // Optional default value
    setOpenModal?: (value: boolean) => void; // Optional default value
    dataId?: number; // Optional default value
    items?: { id: number; title: string }[]; // Optional default value
}

const MediaModal = ({
    value = false,
    setOpenModal = () => {},
    dataId = 0,
    items = [],
}: MediaModalInterface) => {
    const [open, setOpen] = useState(value);
    const [currentId, setCurrentId] = useState(dataId);

    const onCloseModal = () => {
        setOpen(false);
        setOpenModal(false);
    };

    const handleNext = () => {
        if (currentId < items.length - 1) {
            setCurrentId(currentId + 1);
        }
    };

    const handlePrevious = () => {
        if (currentId > 0) {
            setCurrentId(currentId - 1);
        }
    };

    const dateOptions = [
        { date: "12/09/2024", hours: [14, 16, 18] },
        { date: "13/09/2024", hours: [15, 17, 19] },
        { date: "14/09/2024", hours: [11, 13, 15] },
        { date: "15/09/2024", hours: [14, 16, 18] },
    ];

    return (
        <Modal
            wrapClassName="product-box"
            className="modal-dialog-scrollable"
            fade={true}
            centered
            size="xl"
            isOpen={open}
            toggle={onCloseModal}>
            <div className="modal-header">
                {items && <H4>{items[currentId]?.title}</H4>}
                <Btn className="py-0" onClick={onCloseModal} close></Btn>
            </div>
            <div className="modal-body bg-light">
                <Row className="product-box">
                    <Col lg="4" sm="12">
                        <Card>
                            <CardBody>
                                <Carousel
                                    className="owl-carousel owl-theme"
                                    showStatus={false}
                                    showIndicators={false}
                                    showArrows={true}
                                    swipeable={true}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showThumbs={true}>
                                    <div className="item">
                                        <img
                                            src="../assets/images/ecommerce/01.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="item">
                                        <img
                                            src="../assets/images/ecommerce/02.jpg"
                                            alt=""
                                        />
                                    </div>
                                </Carousel>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="8" sm="12">
                        <Card>
                            <ActvityEventModalForm />
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Formik
                    initialValues={{ selectedDate: "", selectedHour: "" }}
                    onSubmit={(values) => {
                        console.log("Reserved:", values);
                        // handleCloseModal();
                    }}>
                    {({ values, setFieldValue }) => {
                        const availableHours = values.selectedDate
                            ? dateOptions.find(
                                  (option) =>
                                      option.date === values.selectedDate
                              )?.hours || []
                            : [];

                        return (
                            <Form className="form-wizard w-100 flex-lg-fill"  >
                                <div className="product-box">
                                    <Row className="gap-2">
                                        <Col sm="3">
                                            <Field
                                                as="select"
                                                name="selectedDate"
                                                className="form-control"
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "selectedDate",
                                                        e.target.value
                                                    );
                                                    setFieldValue(
                                                        "selectedHour",
                                                        ""
                                                    ); // Reset hour selection
                                                }}>
                                                <option value="">
                                                    -- Select a date --
                                                </option>
                                                {dateOptions.map((option, index) => (
                                                    <option key={index} value={option.date}>
                                                        {option.date}
                                                    </option>
                                                ))}
                                            </Field>
                                        </Col>
                                        <Col sm="3">
                                            {values.selectedDate && (
                                                <Field
                                                    as="select"
                                                    name="selectedHour"
                                                    className="form-control">
                                                    <option value="">
                                                        -- Select an hour --
                                                    </option>
                                                    {availableHours.map((hour, index) => (
                                                        <option key={index} value={hour}>
                                                            {hour}:00
                                                        </option>
                                                    ))}
                                                </Field>
                                            )}
                                        </Col>
                                        <Col sm="3" className="align-self-end d-flex" style={{ position:'absolute',left:'0',bottom:'17px'}}>
                                            <Button className="border bg-secondary">
                                                <div className="d-flex align-items-center gap-sm-2 gap-1 text-white">
                                                    Reserve
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <div className="next_previous position-relative pt-0">
                    <Btn
                        onClick={handlePrevious}
                        className="border"
                        disabled={currentId === 0}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Previous <SVG iconId="back-arrow" />
                        </div>
                    </Btn>
                    <Btn
                        onClick={handleNext}
                        className="border"
                        disabled={currentId === items.length - 1}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Next <SVG iconId="front-arrow" />
                        </div>
                    </Btn>
                </div>
            </div>
        </Modal>
    );
};

export default MediaModal;
