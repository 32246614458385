import { useEffect, useState } from "react";
import { Btn, H4 } from "../../../AbstractElements";
import { motion } from "framer-motion";
import { CardBody, Col, Collapse, Form, Label, Modal, Row } from "reactstrap";
import { ChevronDown, ChevronUp } from "react-feather";
import {
    quizMaterialFormInitialValue,
    QuizMaterialFormValidationProp,
    quizMaterialLessonFormValidationSchema,
} from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { ErrorMessage, Formik } from "formik";
 
// import QuizTest from "../../QuizzAnimation/QuizzTest";

export const clothsDetailsData = ["Special Parameters", "Quiz Materials"];

export interface ExamModalInterface {
    value: boolean;
    setquizopen: (value: boolean) => void;
} 

const QuizmaterialBoxValue = ({ value, bgColor }: ExamModalInterface) => {
    const [isOpen, setIsOpen] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState(1);
     
    
 

    const handleSubmit = (values: QuizMaterialFormValidationProp) => {
        // setSubmitError(false);
    };  
     
    return (
        <>
            <div className="mt-2">
                            <div
                                onClick={() => setIsOpen(!isOpen)}
                                className="d-flex bg-gray rounded-2 mb-2 p-2 gap-2 align-items-center justify-content-between">
                                <div className="d-flex gap-2 align-items-center">
                                    <H4 className="text-white">
                                        Quiz Material
                                    </H4>
                                </div>
                                {isOpen ? <ChevronUp /> : <ChevronDown />}
                            </div>
                            <Collapse isOpen={isOpen} className={bgColor}>
                                <CardBody className="py-2 bg-light2 border p-2 mb-2">
                                    <Formik
                                        initialValues={
                                            quizMaterialFormInitialValue
                                        }
                                        onSubmit={handleSubmit}
                                        validationSchema={
                                            quizMaterialLessonFormValidationSchema
                                        }>
                                        {({
                                            errors,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <Form className="form-wizard text-start">
                                                <Row>
                                                    
                                                    <Col
                                                        sm="12"
                                                        className="mb-3">
                                                        <Label check>
                                                            <span className="txt-black fw-bold">
                                                                Title:
                                                            </span>{" "}
                                                            Introduction to
                                                            World History
                                                        </Label>
                                                        <ErrorMessage
                                                            name="title"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </Col>
                                                    
                                                </Row> 
                                                <Row>
                                                <Col
                                                        sm="12"
                                                        className="mb-3">
                                                        <Label check>
                                                            <span className="txt-black fw-bold">
                                                                {" "}
                                                                  Text:{" "}
                                                            </span>
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col
                                                        sm="12"
                                                        className="mb-3">
                                                        <Label check>
                                                            <span className="txt-black fw-bold">
                                                                {" "}
                                                                URL:{" "} <a target="_blank" href="https://1webbb.net/">1webbb.net</a>
                                                            </span>
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col
                                                        sm="12"
                                                        className=" mb-0">
                                                        <Label check>
                                                            <span className="txt-black fw-bold d-flex align-items-center">
                                                                {" "}
                                                                 <span>Audio:{" "}</span>  <audio controls>
        <source src="your-audio-file.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
                                                            </span>
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Collapse>
                        </div>
        </>
    );
};

export default QuizmaterialBoxValue;
