import { useEffect } from "react";
import { H3, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
 
const WinnerScreen = ({setShowWinnerScreen,handleNextQuestion,showWinnerScreen}) => {
    useEffect(() => {
        if (showWinnerScreen== true) {
            
            // Show WinnerScreen for 2 minutes
            setShowWinnerScreen(true);
            setTimeout(() => {
                setShowWinnerScreen(false); // Hide WinnerScreen after 2 minutes
                handleNextQuestion(); // Proceed to next question
            console.log(showWinnerScreen);

            }, 5000); // 2 minutes in milliseconds
        }
    }, [showWinnerScreen ]); 
    return (
        <div className="">
       <div className="page-body bg-no-repeat winnerscreen_bg bg-cover h-100 d-flex justify-content-center align-items-end">
        <div className="position-absolute top-0 h-100 bg-black w-100" style={{opacity:'0.3'}}></div>
        <div className="main_wineerbox overflow-hidden">
            <div  style={{display:"flex"}}>
                <div className=" first_num winner_box" style={{background:"#ba34eb"}}>
                    <div className="mx-auto mt-2">
                    <Image className="img-fluid medal" src={dynamicImage(`first_num.png`)} alt="user image" />
                    <div><H3 className="fs-1 fw-bold text-white">Hitesh</H3></div>
                    <div><H3 className="fs-3 fst-italic text-white">1300+</H3></div>
                    </div>
                </div>
                <div className="  second_num winner_box" style={{background:"#7056db"}}>
                    <div className="mx-auto mt-2">
                    <Image className="img-fluid" src={dynamicImage(`second_num.svg`)}   alt="user image" />
                    <div><H3 className="fs-1 fw-bold text-white">Dharmik</H3></div>
                    <div><H3 className="fs-3 fst-italic text-white">800+</H3></div>
                    </div>
                </div>
                <div className=" third_num winner_box" style={{background:"#5b0b78"}}>
                    <div className="mx-auto mt-2">
                    <Image className="img-fluid " src={dynamicImage(`third_num.svg`)} alt="user image" />
                    <div><H3 className="fs-1 fw-bold text-white">Priyanka</H3></div>
                    <div><H3 className="fs-3 fst-italic text-white">600+</H3></div>
                    </div>
                </div>
            </div>
        </div>
       </div>
        </div>
    );
};

export default WinnerScreen;
